import React from 'react';
import HeroSection from './HeroSection';
import InfoSection from './InfoSection';

const EmpresaPage = () => {
    return (
        <div id="empresa">
            <HeroSection />
            <InfoSection />
        </div>
    )
}

export default EmpresaPage;