import React from 'react';

import { Button } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { NavButtonsMobileWrap, formNavigationStyle } from '../FormElements';

const NavButtonsMobile = ({returnForm, progressForm, invisible_back, invisible_progress}) => {

    return <NavButtonsMobileWrap>
        <Button  
                    onClick={returnForm} 
                    className={formNavigationStyle({ disabled: invisible_back, float: 'left' }).button_mobile}>
                <KeyboardArrowLeft />VOLTAR</Button>
        <Button 
            onClick={progressForm}
            className={formNavigationStyle({ disabled: invisible_progress, float: 'right' }).button_mobile}>
            AVANÇAR<KeyboardArrowRight /></Button>
    </NavButtonsMobileWrap>;
};

export default NavButtonsMobile;