import React from 'react'
import Base from './base';
import EmpresaPage from '../components/pages/empresa';

const Empresa = () => {

    return (
        <Base>
            <EmpresaPage />            
        </Base>
    )
}

export default Empresa;
