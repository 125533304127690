import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    /* justify-content: left; */
    /* align-items: flex-start; */
    /* padding-top: 95px; */
    /* min-height: 1080px; */
    position: relative;
    z-index: 1;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    height: 298px;
    background-color: #32333A;
    width: 100%;
    padding-top: 120px;
`;

export const HeaderContent = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 36px;
    padding-right: 14.58%;
    padding-left: 14.58%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 479px) {
        padding: 0px 32px 0 32px;
    }
`;

export const HeaderTitleContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 479px) {
        flex-direction: column;
        justify-content: unset;
    }
`;

export const HeaderAtualizacaoContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 479px) {
        flex-direction: row;
    }
`;

export const AtualizacaoText = styled.p`
    color: #888888;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    @media screen and (max-width: 479px) {
        font-size: 16px;
        margin-top: 12px;
    }
`;

export const AtualizacaoDateText = styled.p`
    color: #888888;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: right;

    @media screen and (max-width: 479px) {
        font-size: 12px;
    }
`;

export const HeaderH1 = styled.h1`
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 32px;
    font-weight: 400;
    
    @media screen and (max-width: 479px) {
        font-size: 30px;
    }
`;

export const NavigationAndSomarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
`;

export const NavigationContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

export const SectionText = styled.a`
    color: ${({selected}) => (selected ? '#F0803C' : '#888888')} ;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: ${({selected}) => (selected ? 'bolder' : 'regular')};
    margin-right: 90px;
    cursor: pointer;

    @media screen and (max-width: 479px) {
        font-size: 18px;
        margin-right: 30px;
    }
`;

export const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F5F5FF;
`;

export const BodyContent = styled.div`
    width: 100%;
    height: 100%;
    padding-right: 14.58%;
    padding-left: 14.58%;
    display: flex;
    flex-direction: column;
`;

export const RecordsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const RecordCard = styled.div`
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    height: 80px;
    width: 100%;
    margin-bottom: 12px;
    display: flex;
`;

export const RecordCardText = styled.p`
    color: #32333A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
`;

export const HeaderCard = styled.div`
    /* background-color: #fff; */
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 4px;
    height: 80px;
    width: 100%;
    /* margin-bottom: 12px; */
    display: flex;
`;

export const RecordHeaderText = styled.p`
    color: #32333A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
`;

export const HeaderH1Span = styled.span`
    color: #F0803C;
`;