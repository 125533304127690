import React, { useState } from 'react';

import MobileStepper from '@material-ui/core/MobileStepper';

import { progressBarTheme, formNavigationStyle } from "./FormElements";
import { Button } from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';

import useEnterKeyListener from './KeyListener';
import Notification from './ValidationSnackbar';
import MainForm from './MainForm';

const HeroForm = ({initialStep = 0, initialType = null, contemplados = false, cards = [], dialogClose = {}}) => {

    useEnterKeyListener({
        querySelectorToExecuteClick: "#progressButton"
      });

    // States and Logic

    const [activeStep, setActiveStep] = useState(initialStep);
    const [selection, setSelection] = useState({tipo: initialType, valor: null, nome: '', formaContato: null, contatoFone: "", contatoEmail: ''});
    
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = React.useState('Validation Error');

    const iterateSteps = (step) => {
        let newStep = activeStep + step;
        if (newStep >= 0 && newStep < 6) setActiveStep(newStep);
    }

    const returnForm = () => {
        let returnStep = -1;
        
        switch (activeStep) {
            case 1:
                selection.tipo = null;
                break;
            case 2:                
                // Return to form start if jumped ahead
                if (selection.valor == null) {
                    selection.tipo = null;
                    returnStep = -2;
                }
                selection.valor = null;
                break;
            case 4:
                selection.formaContato = null;
                break;
            default:
                break;
        }
        setSelection(selection);
        iterateSteps(returnStep);
    }

    const openNotification = (message) => {
        setNotificationMessage(message);
        setNotificationOpen(true);
    }

    return <>
        <MainForm 
            selection={selection} 
            setSelection={setSelection} 
            activeStep={activeStep} 
            setActiveStep={setActiveStep} 
            openNotification={openNotification} 
            iterateSteps={iterateSteps} 
            returnForm={returnForm}
            contemplados={contemplados}
            cards={cards}
            dialogClose={dialogClose}
        />
        <MobileStepper
                variant="progress"
                steps={5}
                position="static"
                activeStep={activeStep}
                className={progressBarTheme({visible: (activeStep < 5)}).root}
                backButton={
                    <Button 
                        size="small" 
                        onClick={returnForm} disabled={activeStep === 0} 
                        className={formNavigationStyle({ disabled: (activeStep === 5 || (activeStep < 3 && contemplados)) }).button}>
                    <KeyboardArrowLeft />VOLTAR</Button>
                }
            />
        <Notification message={notificationMessage} open={notificationOpen} setOpen={setNotificationOpen} />
    </>
}

export default HeroForm;