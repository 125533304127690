import { Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import {FaFacebook, FaInstagram} from 'react-icons/fa'
import { Column1, Column2, FooterContainer, FooterContentContainer, FooterContentText, FooterHeaderContainer, FooterHeaderText, FooterWrap, SmallImg, SmallImgContainer, SocialIconLink, SocialIcons, SocialMedia, SocialMediaWrap, WebsiteRights } from './FooterElements'
import {animateScroll as scroll} from 'react-scroll';
import FormSlider from '../Common/ContactForm/FormSlider';

const useStyles = makeStyles({
    btn: props => ({
      height: '60px',
      color: '#fff',
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      borderColor: '#fff',
      borderRadius: '8px'
    }),

    icon: props => ({
        "&:hover": {
            color: '#f50057'
        }
    })
  });

const Footer = ({isHome}) => {

    const classes = useStyles();
    const [formOpen, setFormOpen] = useState(false);

    return (
        <>
        <FooterContainer>
            <FooterHeaderContainer>
                <FooterHeaderText>Vamos começar a planejar seu sonho?</FooterHeaderText>
                <Button
                    className={classes.btn}
                    variant="outlined"
                    color="secondary"
                    onClick={() => isHome? scroll.scrollToTop() : setFormOpen(true)}
                >
                    Começar agora
                </Button>
            </FooterHeaderContainer>
            <FooterContentContainer>
                <Column1>
                    <FooterContentText>Razão Social: D.A. Dahmer Investimentos LTDA</FooterContentText>
                    <FooterContentText>CNPJ: 12.831.182/0001-21</FooterContentText>
                    <FooterContentText>Telefone: (51) 99988-8575</FooterContentText>
                    <FooterContentText>E-mail: dirzenio.consorcios@gmail.com</FooterContentText>
                </Column1>
                <Column2>
                        <SmallImg src='/images/hs_consorcios_corretor_autorizado.png' />
                    <SmallImgContainer>
                        <SmallImg src='/images/banco_central.png' />
                        <SmallImg src='/images/abac.png' />
                    </SmallImgContainer>
                </Column2>
            </FooterContentContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <WebsiteRights>Copyright © DAHMER CONSÓRCIOS, {new Date().getFullYear()} </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://www.facebook.com/dahmerconsorcios/" target="_blank" arial-label="Facebook">
                                <FaFacebook className={classes.icon} />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/dahmerconsorcios/" target="_blank" arial-label="Instagram">
                                <FaInstagram className={classes.icon} />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
        <FormSlider open={formOpen} handleClose={() => setFormOpen(false)} />
        </>
    )
}

export default Footer
