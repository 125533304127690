import { sendWhatsAppInteractionEmail } from './email';
import {analytics} from './firebase';

const BASE_LINK = 'https://api.whatsapp.com/send';
const DEFAULT_PHONE = '5551999888575';
const DEFAULT_TEXT = 'Olá, visitei o site de vocês e gostaria saber mais sobre consórcios. ';
const NEW_LINE = '%0A';
const NEW_LINE_END = '%0A%0D';

// Base Texts
const BASE_IMOVEIS = 'Olá, visitei o site de vocês e gostaria saber mais sobre o consórcio de imóveis. ';
const BASE_VEICULOS = 'Olá, visitei o site de vocês e gostaria saber mais sobre o consórcio de veículos. ';
const BASE_INVESTIMENTOS = 'Olá, visitei o site de vocês e gostaria saber mais sobre como investir com consórcios. ';

const BASE_CONTEMPLADOS = 'Olá, visitei o site de vocês e gostaria de mais informações sobre as seguintes cartas:'

function sendMessage(url) {
    logWhatsAppAnalytics();
    window.open(url);
}

function logWhatsAppAnalytics() {
    try {
        analytics.logEvent('WhatsApp');
        sendWhatsAppInteractionEmail();
    } catch (e) {}    
}

export function openWhatsApp(details, phone) {
    let url;
    switch (details.tipo) {
        case 'Imóveis':
            url = imoveis(details, phone);
            break;
        case 'Veículos':
            url = veiculos(details, phone);
            break;
        case 'Investimento':
            url = investimentos(details, phone);
            break;
        default:
            url = formatDetails(DEFAULT_TEXT, details, phone);
            break;
    }
    sendMessage(url);
}

export function openContempladosWhatsApp(cartas) {
    let text = BASE_CONTEMPLADOS;
    
    cartas.forEach((carta) => {
        text += NEW_LINE_END + formatCarta(carta);
    });

    sendMessage(whatsappLink(text + NEW_LINE + NEW_LINE_END));
}

function formatCarta(carta) {    
    return `${carta.tipo}: ${NEW_LINE}- Crédito: ${carta.credito} ${NEW_LINE}- Entrada: ${carta.entrada} ${NEW_LINE}- Parcela ${carta.parcela} ${NEW_LINE}- Prazo ${carta.prazo}`;
}

function imoveis(details, phone) {
    return formatDetails(BASE_IMOVEIS, details, phone);
}

function veiculos(details, phone) {
    return formatDetails(BASE_VEICULOS, details, phone);
}

function investimentos(details, phone) {
    return formatDetails(BASE_INVESTIMENTOS, details, phone);
}

function formatDetails(baseText, details, phone) {
    // if (details) {
    //     baseText += NEW_LINE  + JSON.stringify(details, null, 2); // differentiate by details' type (imoveis, veiculo, investimento)
    // }
    return whatsappLink(baseText, phone);
}

function whatsappLink(text, phone) {
    phone = phone || DEFAULT_PHONE;
    text = text || DEFAULT_TEXT;
    return BASE_LINK + '?phone=' + phone + '&text=' + text;
}
