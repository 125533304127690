import styled from 'styled-components';

export const ImageGrid = styled.div`
    display: grid;
    width: fit-content;
    margin-top: -140px;
    position: absolute;
    z-index: 10;
    top: 500px;

    @media screen and (max-width: 1200px) {
        display: none;
    }
`

export const Row1 = styled.div`
    display: grid;
    grid-template-areas: 'col1 col2';
    
`

export const Column1 = styled.div`
    grid-area: col1;
`

export const Column2 = styled.div`
    grid-area: col2;
    z-index: -1;
    transition: transform 0.5s;
    transform: ${({open}) => (open)? 'translateX(0px)' : 'translateX(-200px)'};
`

export const ImageMiniatureContainer = styled.div`
    width: 140px;
    height: 200px;
    overflow: hidden;
    cursor: pointer;
    border-radius: ${({round_bottom, round_top}) => {
        if (round_bottom) return '0px 0px 20px 0px'
        else if (round_top) return '0px 20px 0px 0px'
        else return '0px';
    } };
`

export const ImageMiniature = styled.img`
    margin: 0 ${({pad}) => pad};
    height: 200px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    /* opacity: 0.7; */
    &:hover {
        -webkit-filter: unset;
        filter: unset;
        /* opacity: 1; */
    }
`

export const TextTile = styled.div`
    width: 200px;
    height: 200px;
    background-color: #1A1A1A;
    border-radius: 0px 20px 20px 0px;
    color: #F1F1F1;
    display: grid;
    align-content: space-between;
    padding: 20px;    
`

export const TextTileId = styled.h1`
    color: #ACACAC;
    font-size: 18px;
    font-stretch: semi-condensed;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`

export const TextTileContent = styled.div`
    
`

export const TextTileName = styled.h1`
    color: #ACACAC;
    font-size: 12px;
    font-stretch: semi-condensed;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-bottom: 5px;
`

export const TextTileItem = styled.h1`
    color: #F1F1F1;
    font-size: 14px;
    font-stretch: semi-condensed;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`