import React from 'react'
import { Container, CardsWrapper, Card, CardHeader, CardText, Title } from './BeneficiosElements';

// icons
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LockIcon from '@material-ui/icons/Lock';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BlockIcon from '@material-ui/icons/Block';
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import { useState } from 'react';

const BeneficiosSection = ({
    width,
    breakpoint
}) => {

    const [isHoverCard2, setIsHoverCard2] = useState(false);
    const [isHoverCard3, setIsHoverCard3] = useState(false);
    const [isHoverCard4, setIsHoverCard4] = useState(false);
    const [isHoverCard5, setIsHoverCard5] = useState(false);
    const [isHoverCard6, setIsHoverCard6] = useState(false);

    return (
        <>
            <Title>Benefícios do Consórcio</Title>
            <Container>
                <CardsWrapper>
                    <Card
                        borderTop={width < breakpoint ? true : false} 
                        borderBottom={true} 
                        borderLeft={false} 
                        borderRight={true}
                        disableBorderRight={isHoverCard2}
                        disableBorderBottom={isHoverCard4}
                    >
                        <LockIcon style={{color: '#2F2F2F', fontSize: '48'}} />
                        <CardHeader>SEGURANÇA</CardHeader>
                        <CardText>A HS Consórcios é uma administradora credenciada e fiscalizada pelo Banco Central e pela Associação Brasileira de Administradoras de Consórcios (ABAC)</CardText>
                    </Card>
                    <Card 
                        borderTop={false} 
                        borderBottom={true} 
                        borderLeft={false} 
                        borderRight={true} 
                        onMouseEnter={() => setIsHoverCard2(true)}
                        onMouseLeave={() => setIsHoverCard2(false)}
                        disableBorderRight={isHoverCard3}
                        disableBorderBottom={isHoverCard5}
                    >
                        <AccountBalanceIcon style={{color: '#2F2F2F', fontSize: '48'}} />
                        <CardHeader>USO DO FGTS</CardHeader>
                        <CardText>Você pode usar seu Fundo de Garantia do Tempo de Serviço (FGTS) para lances e pagamento de parcelas de consórcios de imóveis</CardText>
                    </Card>
                    <Card 
                        borderTop={false} 
                        borderBottom={true} 
                        borderLeft={false} 
                        borderRight={false}
                        onMouseEnter={() => setIsHoverCard3(true)}
                        onMouseLeave={() => setIsHoverCard3(false)}
                        disableBorderBottom={isHoverCard6}
                    >
                        <AttachMoneyIcon style={{color: '#2F2F2F', fontSize: '48'}} />
                        <CardHeader>PARCELAS FLEXÍVEIS</CardHeader>
                        <CardText>Na HS Consórcios você possui flexibilidade quanto às parcelas de seus consórcios. Você tem a possibilidade de pagar meia parcela até a sua contemplação</CardText>
                    </Card>
                    <Card 
                        borderTop={false} 
                        borderBottom={width < breakpoint ? true : false} 
                        borderLeft={false} 
                        borderRight={true} 
                        onMouseEnter={() => setIsHoverCard4(true)}
                        onMouseLeave={() => setIsHoverCard4(false)}
                        disableBorderRight={isHoverCard5}
                    >
                        <ShoppingCartIcon style={{color: '#2F2F2F', fontSize: '48'}} />
                        <CardHeader>PODER DE COMPRA</CardHeader>
                        <CardText>Diferente de outros investimentos, no consórcio você possui um maior poder de compra com uma carta contemplada já que ela equivale a uma compra à vista</CardText>
                    </Card>
                    <Card 
                        borderTop={false} 
                        borderBottom={width < breakpoint ? true : false} 
                        borderLeft={false} 
                        borderRight={true} 
                        onMouseEnter={() => setIsHoverCard5(true)}
                        onMouseLeave={() => setIsHoverCard5(false)}
                        disableBorderRight={isHoverCard6}
                    >
                        <BlockIcon style={{color: '#2F2F2F', fontSize: '48'}} />
                        <CardHeader>SEM JUROS</CardHeader>
                        <CardText>No consórcio você não paga juros sobre as parcelas do seu consórcio, seja ele de imóvel ou veículo</CardText>
                    </Card>
                    <Card 
                        borderTop={false} 
                        borderBottom={false} 
                        borderLeft={false} 
                        borderRight={false} 
                        onMouseEnter={() => setIsHoverCard6(true)}
                        onMouseLeave={() => setIsHoverCard6(false)}
                    >
                        <SwapCallsIcon style={{color: '#2F2F2F', fontSize: '48'}} />
                        <CardHeader>FLEXIBILIDADE</CardHeader>
                        <CardText>Você pode usar o consórcios de diversas formas, seja para aumentar seu patrimônio ou reforçar sua aposentadoria, o consórcio permite a melhor escolha para você</CardText>
                    </Card>
                </CardsWrapper>
            </Container>
        </>
    )
}

export default BeneficiosSection;