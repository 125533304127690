import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import currency from 'currency.js';

// styling
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

// icons
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { LabelText, LabelValueContainer, Separator, SummaryContainer, ValueText } from './SomarCartasElements';
import CloseIcon from '@material-ui/icons/Close';

// integration
import { openContempladosWhatsApp } from '../../../integration/whatsapp';

const useStyles = makeStyles({
    myButton: props => ({
      background: props.backgroundColor,
      "&:hover": {
          backgroundColor: props.backgroundColor
      },
      textTransform: 'none',
    }),
});

const styles = (theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const RenderButtons = ({
    isMobile = false, openContactForm, cartas
}) => {
    const props ={ backgroundColor: '#F0803C' };
    const classes = useStyles(props);
    return (
        <DialogActions>
            <Button
                className={classes.myButton}
                variant="contained"
                color="secondary"
                startIcon={<WhatsAppIcon />}
                onClick={() => openContempladosWhatsApp(cartas)}
            >
                {isMobile ? 'Whats' : 'Enviar Whats'}
            </Button>
            <Button
                className={classes.myButton}
                variant="contained"
                color="secondary"
                startIcon={<MailOutlineRoundedIcon />}
                onClick={openContactForm}
            >
                {isMobile ? 'E-mail' : 'Enviar E-mail'}
            </Button>
        </DialogActions>
    )
}

const RenderSummary = ({
    tipo,
    credito,
    entrada,
    parcela
}) => {
    return (
        <>
            <LabelValueContainer>
                <LabelText>Tipo:</LabelText>
                <ValueText>{tipo}</ValueText>
            </LabelValueContainer>
            <LabelValueContainer>
                <LabelText>Crédito total:</LabelText>
                <ValueText>R$ {credito.format()}</ValueText>
            </LabelValueContainer>
            <LabelValueContainer>
                <LabelText>Entrada total:</LabelText>
                <ValueText>R$ {entrada.format()}</ValueText>
            </LabelValueContainer>
            <LabelValueContainer>
                <LabelText>Prestações de:</LabelText>
                <ValueText>R$ {parcela.format()}</ValueText>
            </LabelValueContainer>        
        </>
    )
}

const sumValues = (
    baseValue,
    addValue
) => {
    return currency(baseValue, {symbol: "" , separator: ".", decimal: ","}).add(addValue);
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const SomarCartasDialog = (props) => {
    const { onClose, open, cartas, openContactForm } = props;
    
    const filteredCartas = cartas.filter(c => c.checked === true);
    const cartasDeImoveis = filteredCartas.filter(c => c.tipo === 'imovel');
    const cartasDeVeiculos = filteredCartas.filter(c => c.tipo === 'veiculo');

    var imoveisCreditoTotal = 0;
    cartasDeImoveis.forEach(function(a) {
        imoveisCreditoTotal = sumValues(imoveisCreditoTotal, a.credito);
    })
    var veiculosCreditoTotal = 0;
    cartasDeVeiculos.forEach(function(a) {
        veiculosCreditoTotal = sumValues(veiculosCreditoTotal, a.credito);
    })

    var imoveisEntradaTotal = 0;
    cartasDeImoveis.forEach(function(a) {
        imoveisEntradaTotal = sumValues(imoveisEntradaTotal, a.entrada);
    })
    var veiculosEntradaTotal = 0;
    cartasDeVeiculos.forEach(function(a) {
        veiculosEntradaTotal = sumValues(veiculosEntradaTotal, a.entrada);
    })

    var imoveisParcelaTotal = 0;
    cartasDeImoveis.forEach(function(a) {
        imoveisParcelaTotal = sumValues(imoveisParcelaTotal, a.parcela);
    })
    var veiculosParcelaTotal = 0;
    cartasDeVeiculos.forEach(function(a) {
        veiculosParcelaTotal = sumValues(veiculosParcelaTotal, a.parcela);
    })

    return (
        <Dialog onClose={() => onClose()} aria-labelledby="simple-dialog-title" open={open} maxWidth="sm" fullWidth>
            <DialogTitle id="customized-dialog-title" onClose={onClose} >{props.isMobile ? 'Resumo' : 'Resumo das cartas selecionadas'}</DialogTitle>
            <DialogContent dividers>
                {imoveisCreditoTotal !== 0 &&
                    <RenderSummary tipo="Imóvel" credito={imoveisCreditoTotal} entrada={imoveisEntradaTotal} parcela={imoveisParcelaTotal} />
                }
                {veiculosCreditoTotal !== 0 &&
                    <>
                        {imoveisCreditoTotal !== 0 &&
                            <Separator />
                        }
                        <RenderSummary tipo="Veículo" credito={veiculosCreditoTotal} entrada={veiculosEntradaTotal} parcela={veiculosParcelaTotal} />
                    </>
                }
            </DialogContent>
            <RenderButtons isMobile={props.isMobile} cartas={filteredCartas} openContactForm={() => openContactForm(filteredCartas)} />
        </Dialog>
    )
}

export default SomarCartasDialog;