import styled from 'styled-components';

export const HeroContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: flex-start;
    height: 1100px;
    position: relative;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 75%);
    z-index: 1;

    @media screen and (max-width: 479px) {
        clip-path: none;
        height: 667px;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 2;
    height: 100%;
    overflow: hidden;
    z-index: 3;
`;

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
`;

export const ScrimBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background-image: linear-gradient(hsla(0, 0%, 0%, 0.5), 70%, hsla(0, 0%, 100%, 0));
`;

export const HeroWrap = styled.div`
    height: 100%;
    width: 100%;
    z-index: 4;

    @media screen and (max-width: 479px) {
        margin: 0 32px 0 32px;
    }
`;

export const Title = styled.h1`
    font-size: 112px;
    text-align: start;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    margin: 200px 0 0 130px;

    @media screen and (max-width: 479px) {
        font-size: 28px;
        margin: 120px 0 0 0;
    }
`;

export const Subtitle = styled.h2`
    font-size: 64px;
    text-align: start;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    margin: 100px 0 0 130px;

    @media screen and (max-width: 479px) {
        font-size: 20px;
        margin: 48px 0 0 0px;
        font-weight: 500;
    }
`;

export const Text = styled.p`
    font-size: 20px;
    text-align: start;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: normal;
    margin: 24px 0 0 130px;
    max-width: 760px;
    line-height: 32px;

    @media screen and (max-width: 479px) {
        font-size: 16px;
        margin: 134px 0 0 0px;
    }
`;