import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    /* justify-content: left; */
    /* align-items: flex-start; */
    /* padding-top: 95px; */
    position: relative;
    z-index: 1;
    flex-direction: column;
`;

export const HeaderContainer = styled.div`
    height: 243px;
    background-color: #32333A;
    width: 100%;
    padding-top: 95px;
`;

export const BodyContainer = styled.div`
    width: 100%;
    padding: 32px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const CardContainer = styled.div`
    width: 100%;
    height: 256px;
    border-radius: 8px;
    box-shadow: 0px 3px 12px #00000029;
    margin-top: 24px;
`;

export const CardHeader = styled.div`
    height: 36px;
    background-color: ${({tipo}) => (tipo === 'imovel' ? '#F0803C' : '#E42323')};
    width: 100%;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardHeaderText = styled.h3`
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
`;

export const CardContentContainer = styled.div`
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: row;
`;

export const CardInfoContainer = styled.div`
    flex-grow: 1;
    height: 100%;
    padding: 20px 0 8px 24px;
    display: flex;
    /* justify-content: space-around; */
    flex-direction: column;
`;

export const CardInfoTextContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const CardInfoTextSmall = styled.h3`
    color: #32333A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
`;

export const CardInfoTextLarge = styled.h2`
    color: #32333A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 600;
`;

export const CardSubTextContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const CardSubTextInfoTextSmall = styled.h3`
    color: #32333A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    font-weight: 400;
`;

export const CardSubTextInfoTextLarge = styled.h2`
    color: #32333A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
`;

export const CardInfoTagsContainer = styled.div`
    height: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const TagContainer = styled.div`
    height: 100%;
    border-radius: 4px;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
`;

export const TagText = styled.p`
    color: #32333A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 8px;
`;

export const CardCheckBoxContainer = styled.div`
    width: 72px;
    height: 100%;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
`;

export const ButtonsCornerContainer = styled.div`
    width: 144px;
    height: 100%;
    display: flex;
`