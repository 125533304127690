import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background-color: #101522;
    padding: 60px 14.58%;

    @media screen and (max-width: 1000px) {
        padding: 60px 32px;
    }
`;

export const FooterWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SocialMedia = styled.section`
    width: 100%;
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const WebsiteRights = styled.small`
    color: #fff;
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
    margin-left: 24px;
`;

export const FooterContentContainer = styled.div`
    width: 100%;
    padding: 120px 0 60px 0;
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: flex-end;
    grid-template-areas: 'col1 col2';

    @media screen and (max-width: 1000px) {
        grid-template-areas: 'col1' 'col2';
        align-items: flex-start;
        padding-top: 60px;
    }
`;

export const Column1 = styled.div`
    grid-area: col1;
`;

export const Column2 = styled.div`
    grid-area: col2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: 1000px) {
        align-items: flex-start;
        padding-top: 60px;
    }
`;

export const FooterContentText = styled.p`
    color: #fff;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top: 16px;
`;

export const SmallImgContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const SmallImg = styled.img`
    width: 160px;
    margin-left: 36px;
    margin-top: 36px;

    @media screen and (max-width: 1000px) {
        margin-left: unset;
    }
`;

export const FooterHeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

export const FooterHeaderText = styled.h1`
    color: #fff;
    font-size: 48px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;

    @media screen and (max-width: 1000px) {
        font-size: 26px;
        margin-bottom: 32px;
    }
`;