import React from 'react'
import Base from './base';
import ImoveisPage from '../components/pages/imoveis';

const Imoveis = () => {

    return (
        <Base>
            <ImoveisPage />            
        </Base>
    )
}

export default Imoveis
