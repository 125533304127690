import { Button, Checkbox, Fab, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { BodyContainer, CardCheckBoxContainer, CardContainer, CardInfoContainer, CardHeader, Container, HeaderContainer, CardContentContainer, ButtonsContainer, CardHeaderText, ButtonsCornerContainer, CardInfoTextSmall, CardInfoTextLarge, CardInfoTagsContainer, CardInfoTextContainer, TagContainer, TagText, CardSubTextContainer, CardSubTextInfoTextSmall, CardSubTextInfoTextLarge } from './MobileElements';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { AtualizacaoText, HeaderAtualizacaoContainer, HeaderContent, HeaderH1, HeaderTitleContainer, NavigationAndSomarContainer, NavigationContent, SectionText } from '../HeroSection/ContempladoElements';
import AddIcon from '@material-ui/icons/Add';
import Notification from '../HeroSection/Snackbar';
import SomarCartasDialog from '../HeroSection/SomarCartasDialog';
import { openContempladosWhatsApp } from '../../../integration/whatsapp';

const useStyles = makeStyles({
    wppButton: props => ({
      background: '#32333A',
      "&:hover": {
          backgroundColor: '#32333A'
      },
      borderRadius: '0px 0px 0px 8px',
      width: '72px'
    }),
    emailButton: props => ({
      background: '#32333A',
      "&:hover": {
          backgroundColor: '#32333A'
      },
      borderRadius: '0px 0px 8px 0px',
      width: '72px'
    }),
    checkbox: props => ({
        transform: "scale(1.2)",
    }),
    sumBtn: props => ({
        background: props.backgroundColor,
        "&:hover": {
            backgroundColor: props.backgroundColor
        },
        textTransform: 'none',
        height: '36px',
        fontSize: '14px',
        width: 'fit-content'
      }),
});

const lastUpdateAt = "31/07/2021";

const SomarCartasButton = ({
    onClick
}) => {
    const props ={ backgroundColor: '#F0803C' };
    const classes = useStyles(props);

    return (
        <Button
            className={classes.sumBtn}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => onClick()}
        >
            Somar cartas
        </Button>
    )
}

const MobileVersion = ({
    contempladosState,
    setContempladosState,
    handleIndividualCheckChange,
    allChecked,
    handleAllChecked,
    onTabClick,
    tabSelected,
    onClickSomarCartas,
    filteredContemplados,
    notificationMessage,
    notificationOpen,
    setNotificationOpen,
    dialogOpen,
    handleDialogClickClose,
    openContactForm
}) => {

    const classes = useStyles();

    const onButtonsClick = (item, isWhats) => {
        isWhats ? 
            openContempladosWhatsApp([item]) :
            openContactForm([item]);
    }

    return (
        <Container>
            <HeaderContainer>
                <HeaderContent>
                    <HeaderTitleContainer>
                        <HeaderH1>Cartas Contempladas</HeaderH1>
                        <HeaderAtualizacaoContainer>
                            <AtualizacaoText>Última atualização em {lastUpdateAt}</AtualizacaoText>
                        </HeaderAtualizacaoContainer>
                    </HeaderTitleContainer>
                    <NavigationAndSomarContainer>
                        <NavigationContent>
                            <SectionText 
                                onClick={() => onTabClick('all')}
                                selected={tabSelected === 'all'}
                            >
                                Todas
                            </SectionText>
                            <SectionText 
                                onClick={() => onTabClick('imovel')}
                                selected={tabSelected === 'imovel'}
                            >
                                Imóveis
                            </SectionText>
                            <SectionText 
                                onClick={() => onTabClick('veiculo')}
                                selected={tabSelected === 'veiculo'}
                            >
                                Veículos
                            </SectionText>
                        </NavigationContent>
                    </NavigationAndSomarContainer>
                </HeaderContent>
            </HeaderContainer>
            <BodyContainer>
                <SomarCartasButton onClick={onClickSomarCartas} />
                {filteredContemplados.map((contemplado) => (
                    <>
                        <CardContainer>
                            <CardHeader tipo={contemplado.tipo}>
                                <CardHeaderText>{contemplado.tipo === 'imovel' ? "Imóvel" : "Veículo"}</CardHeaderText>
                            </CardHeader>
                            <CardContentContainer>
                                <CardInfoContainer>
                                    <CardInfoTextContainer>
                                        <CardInfoTextSmall>Crédito</CardInfoTextSmall>
                                        <CardInfoTextLarge>R$ {contemplado.credito}</CardInfoTextLarge>
                                    </CardInfoTextContainer>
                                    <CardSubTextContainer>
                                        <CardSubTextInfoTextSmall>Entrada</CardSubTextInfoTextSmall>
                                        <CardSubTextInfoTextLarge>R$ {contemplado.entrada}</CardSubTextInfoTextLarge>
                                        <CardSubTextInfoTextSmall>Prazo</CardSubTextInfoTextSmall>
                                        <CardSubTextInfoTextLarge>{contemplado.prazo}</CardSubTextInfoTextLarge>
                                        <CardSubTextInfoTextSmall>Parcela</CardSubTextInfoTextSmall>
                                        <CardSubTextInfoTextLarge>R$ {contemplado.parcela}</CardSubTextInfoTextLarge>
                                    </CardSubTextContainer>
                                    {/* <CardInfoTagsContainer>
                                        <TagContainer>
                                            <TagText>{contemplado.prazo}</TagText>
                                        </TagContainer>
                                        <TagContainer>
                                            <TagText>R$ {contemplado.parcela} ao mês</TagText>
                                        </TagContainer>
                                    </CardInfoTagsContainer> */}
                                </CardInfoContainer>
                                <CardCheckBoxContainer>
                                    <Checkbox
                                        checked={contemplado.checked}
                                        style={{color: '#32333A'}}
                                        className={classes.checkbox}
                                        onChange={() => handleIndividualCheckChange(contemplado.id)}
                                    />
                                </CardCheckBoxContainer>
                            </CardContentContainer>
                        </CardContainer>
                        <ButtonsContainer>
                            <ButtonsCornerContainer>
                                <IconButton 
                                    aria-label="wpp"
                                    className={classes.wppButton}
                                    onClick={() => onButtonsClick(contemplado, true)}
                                >
                                    <WhatsAppIcon 
                                        style={{ color: '#fff', fontSize: "large" }}
                                    />
                                </IconButton>
                                <IconButton 
                                    aria-label="wpp"
                                    className={classes.emailButton}
                                    onClick={() => onButtonsClick(contemplado, false)}
                                >
                                    <MailOutlineRoundedIcon                                         
                                        style={{ color: '#fff', fontSize: "large" }}
                                    />
                                </IconButton>
                            </ButtonsCornerContainer>
                        </ButtonsContainer>
                    </>
                ))}
            </BodyContainer>
            <Notification message={notificationMessage} open={notificationOpen} setOpen={setNotificationOpen} />
            <SomarCartasDialog open={dialogOpen} onClose={handleDialogClickClose} cartas={contempladosState.contemplados} isMobile={true} openContactForm={openContactForm}/>
        </Container>
    )
}

export default MobileVersion;