import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({bgColor}) => (bgColor)};

    @media screen and (max-width: 768) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 1000px;
    width: 100%;
    padding: 0 24px;

    @media screen and (max-width: 1366px) {
        height: 1200px;
    }

    @media screen and (max-width: 479px) {
        height: unset;
        padding: 32px 0 0 0;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    padding-bottom: 72px;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 1366px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
        padding-bottom: 0px;
    }
`;

export const Column1 = styled.div`
    padding: 0 15px;
    grid-area: col1;
    margin: auto;

    @media screen and (max-width: 479px) {
        padding: 0;
    }
`;

export const Column2 = styled.div`
    padding: 0 15px;
    grid-area: col2;
    position: relative;
    margin: auto;
    
    @media screen and (max-width: 479px) {
        padding: 0;
    }
`;

export const TextWrapper = styled.div`
    max-width: 725px;
    padding-top: 0;

    @media screen and (max-width: 479px) {
        padding: 0 32px;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #2F2F2F;
    text-align: left;

    @media screen and (max-width: 479px) {
        font-size: 28px;
        line-height: unset;
        margin-bottom: 32px;
    }
`;

export const Subtitle = styled.p`
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    color: #000;

    @media screen and (max-width: 479px) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const ImgWrap = styled.div`
    max-width: 725px;
    height: 100%;
    position: relative;
    z-index: 10;
`;

export const Img = styled.img`
    width: 100%;
    padding-right: 0;
    box-shadow: 0px 8px 40px #0000004D;
    border-radius: 40px;
    opacity: 1;
    z-index: 10;

    @media screen and (max-width: 479px) {
        box-shadow: unset;
        border-radius: unset;
        vertical-align: -webkit-baseline-middle;
    }    
`;

export const ImageBackgroundShape = styled.div`
    border-radius: ${({borderRadius}) => (borderRadius)};
    height: 100%;
    width: 725px;
    background: ${({bgColor}) => (bgColor)};
    position: absolute;
    bottom: -70px;
    right: ${({imgStart}) => (imgStart ? '80px' : '-60px')};
    z-index: 2;

    @media screen and (max-width: 1740px) {
        display: none;
    }
`;

export const AllButtonsWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    align-items: baseline;

    @media screen and (max-width: 1600px) {
        margin: 0 auto;
        margin-top: 32px;
    }

    @media screen and (max-width: 479px) {
        margin-top: 32px;
    }    
`;

export const SendWppEmailWrapper = styled.div`
    flex-direction: row;
    display: flex;
    margin-bottom: 20px;
    width: 100%;

    @media screen and (max-width: 479px) {
        margin-bottom: 32px;
        flex-direction: column;
    }
`;

export const ParcelasWrap = styled.div`
    width: 214px;
    height: 239px;
    position: absolute;
    bottom: -175px;
    right: ${({imgStart}) => imgStart ? '40px' : 'unset'};
    left: ${({imgStart}) => imgStart ? 'unset' : '-40px'};

    @media screen and (max-width: 1366px) {
        display: none;
    }
`;

export const ParcelasContainer = styled.div`
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 6px 24px #0000004D;
    border-radius: 0px 0px 40px 40px;
    z-index: 20;
    width: 100%;
    height: 179px;
    bottom: 0;
`;

export const ParcelasTitleWrap = styled.div`
    position: absolute;
    background-color: ${({bgColor}) => (bgColor)};
    height: 60px;
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    z-index: 21;
    top: 0;
`;

export const ParcelasTitleText = styled.p`
    font-size: 24px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
    line-height: 60px;
    text-align: center;
    color: #fff;    
`;

export const ParcelasBodyLargerText = styled.p`
    font-size: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    padding-top: 12px;
    color: #2f2f2f;    
`;

export const ParcelasBodySmallerText = styled.p`
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: lighter;
    text-align: center;
    padding: 13px 18px 0px 18px;
    color: #2f2f2f;    
`;

export const ParcelasDivider = styled.hr`
    border-top: 2px solid ${({color}) => (color)};
`;