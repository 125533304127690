import React from 'react';
import { HeroButton, NormalText, ProgressButton } from '../FormElements';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { openWhatsApp } from '../../../../../../integration/whatsapp';

const EndButtons = ({currentStep, selection, backToStart, contemplados}) => {

    const formatPhone = (phone) => {
        //Filter only numbers from the input
        let cleaned = ('' + phone).replace(/\D/g, '');
        
        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
        
        if (match) {
            return '(' + match[2] + ') ' + match[3] + '-' + match[4];
        };
        
        return '';
    }

    return <>
        {currentStep.buttons.map((i, index) => {
            return <HeroButton onClickAction={() => openWhatsApp(selection, i.tel)} key={index}>
                    <WhatsAppIcon style={{verticalAlign: "text-top"}} />&nbsp;<NormalText>{i.label}</NormalText><br />&nbsp;{formatPhone(i.tel)}
                </HeroButton>;
        })}
        <br /><br />
        <ProgressButton onClickAction={backToStart} bgColor='#247F84'>{currentStep.progress_label}</ProgressButton>
    </>;
};

export default EndButtons;