const utilitarioImg = '/images/veiculos-hero-bg-utilitario.jpg';
const motoImg = '/images/veiculos-hero-bg-moto.jpg';
const carroImg = '/images/veiculos-hero-bg-carro.png';

export const Utilitario = {
    img: utilitarioImg,
    title: 'Utilitários e Caminhões',
    id: '02.',
    pad: '-160px',
    text: 'Se você está pensando em começar, aumentar ou ainda renovar a sua frota de veículos, o Consórcio é a sua melhor escolha. \nEntre em contato e nós vamos te ajudar a expandir o seu negócio!',
    buttonColors: ['#254d72', '#033B72']
}

export const Moto = {
    img: motoImg,
    title: 'Motocicletas',
    id: '03.',
    pad: '-90px',
    text: 'Se você está precisando de maior mobilidade nos seus deslocamentos diários ou ainda é um entusiasta por motocicletas, entre em contato com a Dahmer Consórcios que nós vamos te auxiliar com o seu próximo investimento.',
    buttonColors: ['#DF6E3A', '#B15023']
}

export const Carro = {
    img: carroImg,
    title: 'Carros',
    id: '01.',
    pad: '-115px',
    text: `O consórcio de veículos possui diversas vantagens, desde parcelas que cabem no seu orçamento até usar o seu veículo usado como lance. \nVeja algumas outras vantagens abaixo e entre em contato conosco!`,
    buttonColors: ['#C14855', '#982935']
}
