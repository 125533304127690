import React from 'react'
import { CloseIcon, Icon, SidebarContainer, SidebarLink, SidebarMenu, SidebarWrapper } from './SidebarElements'

const Sidebar = ({
    isOpen,
    toggle
}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/" onClick={toggle}>
                        Home
                    </SidebarLink>
                    <SidebarLink to="/imoveis" onClick={toggle} >
                        Imóveis
                    </SidebarLink>
                    <SidebarLink to="/veiculos" onClick={toggle} >
                        Veículos
                    </SidebarLink>
                    <SidebarLink to="/investimentos" onClick={toggle} >
                        Investimentos
                    </SidebarLink>
                    <SidebarLink to="/contemplados" onClick={toggle} >
                        Contemplados
                    </SidebarLink>
                    <SidebarLink to="/empresa" onClick={toggle} >
                        Empresa
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar;
