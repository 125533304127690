import React from 'react';
import { HeroBg, HeroContainer, HeroWrap, ImageBg, Title, Subtitle, Text, ScrimBg } from './HeroElements';

const HeroSection = () => {
    return (
        <HeroContainer>
            <HeroBg>
                <ImageBg src="/images/empresa_2.jpg" />
                <ScrimBg />
            </HeroBg>
            <HeroWrap>
                <Title>Quem Somos</Title>
                <Subtitle>Fundada em 2010</Subtitle>
                <Text>Com mais de 20 anos de experiência no segmento de Consórcios e situados na cidade de Dois Irmãos, sede da HS Consórcios, estamos prontos para oferecer um atendimento personalizado aos nossos clientes.</Text>
            </HeroWrap>
        </HeroContainer>
    )
}

export default HeroSection;