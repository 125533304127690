import React, { useState }  from 'react';
import { Column1, Column2, ImageGrid, ImageMiniature, ImageMiniatureContainer, Row1, TextTile, TextTileContent, TextTileId, TextTileItem, TextTileName } from './BgSelectorComponents';

export default function BgSelector({images, setImages, setClicked}) {

    const [hoverTop, setHoverTop] = useState(false);
    const [hoverBottom, setHoverBottom] = useState(false);

    return (
        <ImageGrid>
            <Row1>
                <Column1>
                    <ImageMiniatureContainer round_top={!hoverTop} onClick={() => {setClicked(true); setImages({selected: images.top, top: images.selected, bottom: images.bottom})}}>
                        <ImageMiniature src={images.top.img} alt={images.top.title} pad={images.top.pad} onMouseOver={() => setHoverTop(true)} onMouseOut={() => setHoverTop(false)} />
                    </ImageMiniatureContainer>
                </Column1>
                <Column2 open={hoverTop}>
                    <TextTile>
                        <TextTileId>{images.top.id}</TextTileId>
                        <TextTileContent>
                            <TextTileName>Veículos</TextTileName>
                            <TextTileItem>{images.top.title}</TextTileItem>
                        </TextTileContent>
                        
                    </TextTile>
                </Column2>
            </Row1>
            <Row1>
                <Column1>
                    <ImageMiniatureContainer round_bottom={!hoverBottom} onClick={() => {setClicked(true); setImages({selected: images.bottom, bottom: images.selected, top: images.top})}}>
                        <ImageMiniature src={images.bottom.img} alt={images.bottom.title} pad={images.bottom.pad} onMouseOver={() => setHoverBottom(true)} onMouseOut={() => setHoverBottom(false)} />
                    </ImageMiniatureContainer>
                </Column1>
                <Column2 open={hoverBottom}>
                    <TextTile>
                        <TextTileId>{images.bottom.id}</TextTileId>
                        <TextTileContent>
                            <TextTileName>Veículos</TextTileName>
                            <TextTileItem>{images.bottom.title}</TextTileItem>
                        </TextTileContent>
                        
                    </TextTile>
                </Column2>
            </Row1>
            
            
        </ImageGrid>
    )
}
