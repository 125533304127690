import React from 'react'
import TrianglesGroup from '../TrianglesGroup'

import { 
    Column1, 
    Column2, 
    Heading, 
    Img, 
    ImgWrap, 
    InfoContainer, 
    InfoRow, 
    InfoWrapper, 
    Subtitle, 
    TextWrapper,
    ArgumentNumber
} from './InfoElements'

const InfoSection = ({
    bgColor,
    id, 
    imgStart,    
    argNumberStart,
    cards,
    img,
    alt
}) => {
    return (
        <InfoContainer bgColor={bgColor} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1 imgStart={imgStart}>
                        <ImgWrap imgStart={imgStart}>
                            <Img src={img} alt={alt} />
                            <TrianglesGroup rows={4} columns={5} color="#383838" left={(imgStart ? '330px' : '-270px')} bottom="-40px" />
                        </ImgWrap>
                    </Column1>
                    <Column2 imgStart={imgStart}>
                        {cards.map((card, index) => {
                            return <div key={index} >
                                <TextWrapper>
                                    <ArgumentNumber>{formatArgumentNumber(argNumberStart + index)}.</ArgumentNumber>
                                    <Heading >{card.title}</Heading>
                                    <Subtitle>{card.description}</Subtitle>
                                </TextWrapper>
                            </div>;
                        })}
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    )
}

const formatArgumentNumber = (num) => {
    return num.toLocaleString('pt-BR', {minimumIntegerDigits: 2})
};

export default InfoSection
