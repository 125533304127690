import styled from 'styled-components';

export const Header = styled.div`
    height: 300px;
    position: relative;
    z-index: 1;
    text-align: center;

    @media screen and (max-width: 479px) {
        height: unset;
    }
`;

export const HeaderContent = styled.div`
    max-width: 688px;
    margin: auto;
    @media screen and (max-width: 750px) {
        max-width: 500px;
    }
    @media screen and (max-width: 550px) {
        max-width: 400px;
    }
    
    @media screen and (max-width: 479px) {
        padding: 0 32px;
        margin: unset;
    }
`

export const HeaderH1 = styled.h1`
    color: #2F2F2F;
    font: normal normal bold 32px/20px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top: 70px;
    padding-bottom: 35px;
    line-height: normal;

    @media screen and (max-width: 750px) {
        padding-top: 40px;
        font-size: 25px;
    }

    @media screen and (max-width: 479px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }
`;

export const HeaderSubtitle = styled.p`
    color: #6A6A6A;
    font: normal normal normal 16px/24px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    @media screen and (max-width: 750px) {
        font-size: 14px;
    }

    @media screen and (max-width: 479px) {
        padding-bottom: 32px;
        font-size: 16px;
        line-height: 24px;
    }
`;

export const ComparisonContent = styled.div`
    height: 1900px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1200px) {
        height: 4400px;
    }
    
    @media screen and (max-width: 479px) {
        height: 4600px;
    }
`;

export const ComparisonBg = styled.div`
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 2;
    height: 1900px;
    overflow: hidden;
    z-index: 3;
    
    @media screen and (max-width: 1200px) {
        height: 4400px;
    }
    
    @media screen and (max-width: 479px) {
        height: 4600px;
    }
`;

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`;

export const ComparisonWrap = styled.div`
    position: absolute;
    width: 100%;
    padding-top: 45px;
    height: 1080px;
    z-index: 4;
    text-align: center;

    @media screen and (max-width: 479px) {
        padding-top: 0px;
    }
`;

export const ComparisonInfoH1 = styled.h1`
    color: #FFFFFF;
    font: normal normal 600 32px/24px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-bottom: 17px;

    @media screen and (max-width: 479px) {
        font-size: 28px;
        padding-bottom: 16px;
        padding-top: 32px;
    }
`

export const ComparisonInfoSubtitle = styled.p`
    color: #FFFFFF;
    font: normal normal 400 16px/24px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    @media screen and (max-width: 479px) {
        padding: 0 30px;
    }
`;

export const CardTable = styled.div`
    padding: 50px 300px;
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    padding-bottom: 72px;
    grid-template-areas: 'col1 col2 col3';

    @media screen and (max-width: 1700px) {
        padding: 50px 200px;
    }
    
    @media screen and (max-width: 1500px) {
        padding: 50px 100px;
    }
    
    @media screen and (max-width: 1200px) {        
        grid-template-areas: 'col1' 'col2' 'col3';
        padding: 30px 50px;
    }

    @media screen and (max-width: 479px) {
        padding: 0px 0px;
    }
`;


export const CardColumn = styled.div`
    grid-area: ${(column) => 'col' + column};
    margin: auto;
    height: 430px;

    @media screen and (max-width: 1200px) {
        height: ${({cardFocus}) => cardFocus? '500px': '430px'};
    }

    @media screen and (max-width: 479px) {
        height: 430px;
        margin-top: 32px;
    }
`;

export const Card = styled.div`
    border-radius: 8px;
    height: ${({cardFocus}) => cardFocus? '430px': '380px'};
    width: ${({cardFocus}) => cardFocus? '421px': '320px'};
    background: #FFFFFF;
    z-index: 2;
    transform: translateY(${({cardFocus}) => cardFocus? '-220px': '-170px'});
    position: relative;
    padding: 0px 20px;
    text-align: left; 

    @media screen and (max-width: 1200px) {
        padding: ${({cardFocus}) => cardFocus? '40px 20px': '0px 20px'};
        width: ${({cardFocus}) => cardFocus? '421px': '320px'};
    }

    @media screen and (max-width: 479px) {
        padding: 0px 20px;
        height: 380px;
        width: 320px;
        transform: translateY(-170px);
    }
`;

export const Img = styled.img`
    width: ${({cardFocus}) => cardFocus? '379px': '288px'};;
    height: ${({cardFocus}) => cardFocus? '264px': '200px'};;
    padding-right: 0;
    box-shadow: 0px 8px 40px #0000004D;
    border-radius: 10px;
    opacity: 1;
    z-index: 10;
    position: relative;    

    @media screen and (max-width: 500px) {
        width: 288px;
        height: 200px;
    }
`;

export const CardInfoHeader = styled.h1`
    color: #2F2F2F;
    font: normal normal 600 32px/20px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top: ${({cardFocus}) => cardFocus? '250px': '200px'};

    @media screen and (max-width: 1200px) {
        padding-top: 200px;
    }
    
`;

export const CardInfoSub = styled.p`
    color: #6A6A6A;
    font: normal normal normal 14px/24px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height: 87px;
    padding: 25px 0px;

    @media screen and (max-width: 479px) {
        padding-top: 15px;
        font-size: 16px;
    }
`;

export const CardInfoCaption = styled.h1`
    color: #2F2F2F;    
    font: normal normal bold 16px/20px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-top: 35px;
`;