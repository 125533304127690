import React from 'react';
import { NameButtonsWrap, ProgressButton, TextInput } from '../FormElements';
import NavButtonsMobile from './NavButtonsMobile';

const NameButtons = ({currentStep, name, setName, progressForm, returnForm, alert}) => {

    const handleSubmit = () => {
        if (!name) {
            alert('Para prosseguir, favor preencher seu nome');
            return;
        }
        progressForm(currentStep.type, currentStep.buttons[0], name);
    }

    return <>
        <NameButtonsWrap>
            <TextInput autoFocus value={name} onChange={e => {setName(e.target.value)}} /><br />
        </NameButtonsWrap>
        <ProgressButton onClickAction={() => handleSubmit()} bgColor='#247F84' nonMobile={true}>{currentStep.buttons[0].label}</ProgressButton>
        <NavButtonsMobile returnForm={returnForm} progressForm={() => handleSubmit()} invisible_back={false} invisible_progress={false} />
    </>;
};

export default NameButtons;