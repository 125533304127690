import React from 'react';

// components
import Snackbar from '@material-ui/core/Snackbar';
import { IconButton } from '@material-ui/core';

// icons
import CloseIcon from '@material-ui/icons/Close';

const Notification = ({
    message,
    open,
    setOpen
}) => {
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    
    return (
        <Snackbar
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            message={message}
            action={
            <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            }
        />
    )
}

export default Notification;