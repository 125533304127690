const FinanciamentoImg = '/images/home-comparison-financ-1.jpg';
const ConsorcioImg = '/images/home-comparison-consorcio-1.jpg';
const EconomiaImg = '/images/investimento.jpg';

export const FinanciamentoRow = {
    header: 'FINANCIAMENTO',
    subtitle: 'Primeiro, veja um exemplo de um Financiamento',
    image1: FinanciamentoImg,
    image2: FinanciamentoImg,
    image3: FinanciamentoImg,
    infoHeader1: 'R$ 200.000,00',
    infoHeader2: 'R$ 558.000,00',
    infoHeader3: '420 meses',
    infoSub1: 'Valor do crédito disponível para você utilizar ao realizar um financiamento.',
    infoSub2: 'Comparado ao valor do crédito que você pegou com o seu banco, o seu saldo devedor acaba sendo quase 3x maior!',
    infoSub3: 'Com prazos enormes, não flexíveis e taxas abusivas de juros, financiamentos geram uma dívida ainda maior.',
    infoCaption1: 'VALOR DO CRÉDITO',
    infoCaption2: 'SALDO DEVEDOR',
    infoCaption3: 'PARCELAS'
};

export const ConsorcioRow = {
    header: 'CONSÓRCIO',
    subtitle: 'Agora, vamos ver como funciona no Consórcio',
    image1: ConsorcioImg,
    image2: ConsorcioImg,
    image3: ConsorcioImg,
    infoHeader1: 'R$ 200.000,00',
    infoHeader2: 'R$ 245.988,00',
    infoHeader3: '180 meses',
    infoSub1: 'Valor do crédito disponível para você utilizar ao realizar um consórcio.',
    infoSub2: 'A flexibilidade do consórcio e as suas taxas baixíssimas de juros fazem você ter um saldo devedor muito baixo.',
    infoSub3: 'No consórcio os prazos limites são menores e totalmente flexíveis para você definir o tempo que funciona melhor para você!',
    infoCaption1: 'VALOR DO CRÉDITO',
    infoCaption2: 'SALDO DEVEDOR',
    infoCaption3: 'PARCELAS'
};

export const ResultsRow = {
    header: 'RESULTADO',
    subtitle: 'Por fim, veja a diferença entre eles. Esse é um dos motivos da HS Consórcios ser líder de mercado!',
    image1: ConsorcioImg,
    image2: EconomiaImg,
    image3: FinanciamentoImg,
    infoHeader1: 'R$ 245.988,00',
    infoHeader2: 'R$ 312.012,00',
    infoHeader3: 'R$ 558.000,00',
    infoSub1: 'Saldo devedor do Consórcio. Lembre-se que você também pode vender sua carta ao ser contemplado, gerando lucros enormes!',
    infoSub2: 'Com a HS Consórcios você economiza R$ 312.012,00, aproximadamente 156% do valor do crédito!',
    infoSub3: 'Saldo devedor do Financiamento. Não há muito o que falar, não é mesmo?',
    infoCaption1: 'CONSÓRCIO',
    infoCaption2: 'ECONOMIA',
    infoCaption3: 'FINANCIAMENTO',
    focus: [false, true, false]
};
