import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({bgColor}) => (bgColor)};

    @media screen and (max-width: 768) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 600px;
    width: 100%;

    @media screen and (max-width: 1200px) {
        height: 960px;
    }

    @media screen and (max-width: 479px) {
        height: unset;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 2fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 1200px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
        padding-bottom: 0px;
    }

    @media screen and (max-width: 479px) {
        grid-template-areas: 'col1' 'col2';
    }
`;

export const Column1 = styled.div`
    padding: 0 15px;
    grid-area: col1;
    margin: auto;

    @media screen and (min-width: 1600px) {
        padding-right: ${({imgStart}) => (imgStart ? '300px' : 'none')};
    }

    @media screen and (max-width: 479px) {
        margin: unset;
        padding: unset;
        background-color: ${({mobileBgColor}) => (mobileBgColor)};
        padding-bottom: 24px;
    }
`;

export const Column2 = styled.div`
    padding: 0 15px;
    grid-area: col2;
    position: relative;
    margin: auto;

    @media screen and (max-width: 479px) {
        margin: unset;
        padding: unset;
    }
`;

export const TextWrapper = styled.div`
    max-width: 520px;
    padding-top: 0;

    @media screen and (max-width: 479px) {
        margin: 32px 32px 0 32px;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #2F2F2F;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    @media screen and (max-width: 479px) {
        font-size: 32px;
        color: ${({mobileTextColor}) => (mobileTextColor)}
    }
`;

export const Subtitle = styled.p`
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    color: #000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    @media screen and (max-width: 479px) {
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: ${({mobileTextColor}) => (mobileTextColor)}
    }
`;

export const ImgWrap = styled.div`
    max-width: 520px;
    height: 100%;
    position: relative;
    z-index: 10;
`;

export const Img = styled.img`
    width: 100%;
    padding-right: 0;
    box-shadow: 0px 8px 40px #0000004D;
    border-radius: 40px;
    opacity: 1;
    z-index: 10;

    @media screen and (max-width: 479px) {
        box-shadow: unset;
        border-radius: unset;
        vertical-align: -webkit-baseline-middle;
    }
`;

export const ImageBackgroundShape = styled.div`
    border-radius: ${({borderRadius}) => (borderRadius)};
    height: 100%;
    width: 520px;
    background: ${({bgColor}) => (bgColor)};
    position: absolute;
    bottom: 40px;
    right: -40px;
    z-index: 2;

    @media screen and (max-width: 680px) {
        display: none;
    }
`;