import React from 'react';
import { Triangle, TriangleRow, TriangleWrapper } from './TriangleElements';

const emptyList = (size) => {
    return new Array(size).fill(null);
}

const TrianglesGroup = ({rows, columns, color, left, bottom}) => {
    return <TriangleWrapper left={left} bottom={bottom}>
            {emptyList(rows).map((value, indexRow) => 
                <TriangleRow key={indexRow}>
                    {emptyList(columns).map((valueC, indexC) => <Triangle key={indexC} color={color} />)}
                </TriangleRow>
            )}
            </TriangleWrapper>
}

export default TrianglesGroup;
