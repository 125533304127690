import React from 'react';

import { Column1, Column2, Heading, Img, ImgWrap, InfoContainer, InfoRow, InfoWrapper, LargeSubText, LargeText, LargeTextContainer, Subtitle, TextWrapper} from './InfoElements';
import TrianglesGroup from '../../../base/Common/TrianglesGroup';

const InfoSection = () => {
    return (
        <>
            <InfoContainer bgColor="#fff">
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                                <LargeTextContainer>
                                    <LargeText>41</LargeText>
                                    <LargeSubText>%</LargeSubText>
                                </LargeTextContainer>
                                <Heading >Clientes contemplados ativos</Heading>
                                <Subtitle>
                                A Dahmer Consórcios possui um atendimento diferenciado e comprometido com seus clientes através de um pós-vendas mensal com auxílio e orientação na questão de oferta de lances, segunda via, resultado de assembleia, processos de contemplação para que os Sonhos/Projetos de Vida de seus clientes se tornem realidade.</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src="/images/couple.jpg" alt="couple" />
                            </ImgWrap>
                            <TrianglesGroup rows={5} columns={6} color="#F4A545" left="550px" bottom="-80px" />
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>   
            <InfoContainer bgColor="#CB4D55" polygon="polygon2">
                <InfoWrapper height="600px">
                    <InfoRow>
                        <Column1>
                            <TextWrapper>
                                <LargeTextContainer>
                                    <LargeText textColor="#fff">+850</LargeText>
                                </LargeTextContainer>
                                <Heading textColor="#fff" fontWeight="400">Clientes contemplados <br></br>por mês</Heading>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <TextWrapper marginTop="220px" marginBottom= "32px" marginTopMobile="0px">
                                <Subtitle textColor="#fff" lineHeight="48px">
                                A administradora HS Consórcios é líder de mercado no Rio Grande do Sul e está entre as 3 maiores administradoras independentes do pais, contemplando mais de 850 clientes ao mês por meio de seus consórcios.
O número de clientes felizes vem crescendo nos últimos anos, aproveite e venha tornar o seu sonho realidade conosco.</Subtitle>
                            </TextWrapper>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>   
        </>
    )
}

export default InfoSection;
