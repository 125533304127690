import React, {useEffect, useState} from 'react'
import { FaBars } from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import {animateScroll as scroll} from 'react-scroll';
import { MobileIcon, Nav, NavbarContainer, NavItem, NavLogo, NavMenu, Img, NavExtLinks, NavLogoHS, } from './NavbarElements'

const Image = '/images/logo_dahmer.png';
const LogoHs = '/images/logo_hs.png';

const Navbar = ({
    toggle
}) => {
    const[scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY > 95) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
        <IconContext.Provider value={{ color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to="/" onClick={toggleHome}>
                        <Img src={Image} />
                    </NavLogo>
                    <NavMenu>
                        <NavItem>
                            <NavExtLinks to="/">Home</NavExtLinks>
                        </NavItem>
                        <NavItem>
                            <NavExtLinks to="/imoveis">Imóveis</NavExtLinks>
                        </NavItem>
                        <NavItem>
                            <NavExtLinks to="/veiculos">Veículos</NavExtLinks>
                        </NavItem>
                        <NavItem>
                            <NavExtLinks to="/investimentos">Investimentos</NavExtLinks>
                        </NavItem>
                        <NavItem>
                            <NavExtLinks to="/contemplados" >Contemplados</NavExtLinks>
                        </NavItem>
                        <NavItem>
                            <NavExtLinks to="/empresa" >Empresa</NavExtLinks>
                        </NavItem>
                    </NavMenu>
                <NavLogoHS>
                    <Img src={LogoHs} />
                </NavLogoHS>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                </NavbarContainer>
              </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
