import React from 'react';

// components
import { Button } from "@material-ui/core";

// icons
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

// styling
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    myButton: props => ({
      background: props.backgroundColor,
      "&:hover": {
          backgroundColor: props.backgroundColor
      },
      textTransform: 'none',
    }),
});

const Buttons = ({
    item,
    onClick
}) => {
    const props ={ backgroundColor: '#32333A' };
    const classes = useStyles(props);
    
    return (
        <>
            <Button 
                className={classes.myButton}
                variant="contained"
                color="secondary"
                startIcon={<WhatsAppIcon />}
                style={{marginRight: '12px'}}
                onClick={() => onClick(item, true)}
            >
                Whats
            </Button>
            <Button 
                className={classes.myButton}
                variant="contained"
                color="secondary"
                startIcon={<MailOutlineRoundedIcon />}
                onClick={() => onClick(item, false)}
            >
                E-mail
            </Button>
        </>
    )
}

export default Buttons;