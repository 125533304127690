import styled from 'styled-components';

export const Container = styled.div`
    height: 1080px;
    display: flex;
    z-index: 1;
    position: relative;
    text-align: center;

    @media screen and (max-width: 760px) {
        height: 1920px;
    }

    @media screen and (max-width: 479px) {
        height: unset;
    }
`;

export const Background = styled.div`
    display: flex;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    height: 100%;
`;

export const ImageBg = styled.img`
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 479px) {
        vertical-align: -webkit-baseline-middle;
        height: 170px;
    }
`;

export const ScrimBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background-image: linear-gradient(hsla(0, 0%, 0%, 0.5), 70%, hsla(0, 0%, 100%, 0));
`;

export const MobileBigTextAndImageContainer = styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    height: 170px;
`;

export const ContentContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    justify-content: space-between;

    @media screen and (max-width: 479px) {
        position: relative;
        padding-bottom: 24px;
        background-color: #fff;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    @media screen and (max-width: 479px) {
        margin-left: 32px;
        margin-right: 32px;
    }
`;

export const Title = styled.h1`
    font-size: 32px;
    text-align: center;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    @media screen and (max-width: 479px) {
        font-size: 26px;
        text-align: left;
        text-transform: initial;
        color: #2F2F2F;
    }
`;

export const Text = styled.p`
    font-size: 18px;
    text-align: center;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: normal;
    max-width: 800px;
    margin-top: 32px;

    @media screen and (max-width: 479px) {
        font-size: 16px;
        text-align: left;
        margin-top: 24px;
        line-height: 1.5;
        color: #2F2F2F;
    }
`;

export const BigTextContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 479px) {
        z-index: 3;
        width: 100%;
        height: 100%;
    }
`;

export const BigText = styled.h1`
    font-size: 108px;
    text-align: center;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;

    @media screen and (max-width: 479px) {
        font-size: 36px;
    }
`;

export const BigTextSub = styled.p`
    font-size: 24px;
    text-align: center;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;

    @media screen and (max-width: 479px) {
        font-size: 28px;
        padding: 32px 0 20px 0;
    }
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    flex-direction: row;
    padding-bottom: 42px;

    @media screen and (max-width: 1500px) {
        grid-template-columns: auto auto;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    @media screen and (max-width: 760px) {
        grid-template-columns: auto;
        grid-row-gap: 20px;
    }

    @media screen and (max-width: 479px) {
        grid-template-columns: auto auto;
        grid-row-gap: unset;
        grid-column-gap: unset;
        padding: 0px;
    }
`;

export const Card = styled.div`
    display: flex;
    height: 240px;
    width: 360px;
    background-color: #17181D;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 479px) {
        height: 80px;
        width: unset;
    }
`;

export const CardTitle = styled.p`
    font-size: 18px;
    text-align: center;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: normal;

    @media screen and (max-width: 479px) {
        font-size: 12px;
    }
`;

export const CardText = styled.p`
    font-size: 24px;
    text-align: center;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    
    @media screen and (max-width: 479px) {
        font-size: 18px;
    }
`;