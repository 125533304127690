import styled from 'styled-components';

export const Container = styled.div`
    background: #fff;
    height: 900px;

    @media screen and (max-width: 479px) {
        height: unset;
    }
`;

export const Title = styled.h1`
    font-size: 48px;
    text-align: center;
    color: #2f2f2f;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    margin-top: 3%;

    @media screen and (max-width: 479px) {
        font-size: 26px;
        margin-top: 32px;
        margin-bottom: 32px;
    }
`;

export const CardsWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    background-color: #fff;
    padding: 5.2% 15.60%;

    @media screen and (max-width: 479px) {
        grid-template-columns: auto;
        padding: 0 32px 0 32px;
    }
`;

export const Card = styled.div`
    background-color: #fff;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 64px 48px 30px 48px;

    border-top: ${({borderTop, disableBorderTop}) => (borderTop && !disableBorderTop ? '0.5px solid rgba(0,0,0, 0.1)' : '0.5px solid #fff')};
    border-bottom: ${({borderBottom, disableBorderBottom}) => (borderBottom && !disableBorderBottom ? '0.5px solid rgba(0,0,0, 0.1)' : '0.5px solid #fff')};
    border-left: ${({borderLeft, disableBorderLeft}) => (borderLeft && !disableBorderLeft ? '0.5px solid rgba(0,0,0, 0.1)' : '0.5px solid #fff')};
    border-right: ${({borderRight, disableBorderRight}) => (borderRight && !disableBorderRight ? '0.5px solid rgba(0,0,0, 0.1)' : '0.5px solid #fff')};

    &:hover {
        border: 0.5px solid #fff;
        box-shadow: 0px 2px 80px 0px rgb(0, 0, 0, 0.1);
        transition-property: border, box-shadow;
        transition-duration: 300ms, 300ms;
        transition-timing-function: ease, ease;
        transition-delay: 0ms, 0ms;
        z-index: 15;
    }

    @media screen and (max-width: 479px) {
        padding: 52px 0 52px 0;
        border-right: unset;
        border-left: unset;
    }
`;

export const CardHeader = styled.p`
    font-size: 20px;
    text-align: center;
    color: #2f2f2f;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;

    @media screen and (max-width: 479px) {
        font-size: 26px;
        margin-bottom: 12px;
        margin-top: 12px;
    }
`;

export const CardText = styled.p`
    font-size: 16px;
    text-align: center;
    color: #2f2f2f;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    line-height: 24px;
`;