import React from 'react'
import { HeroBg, HeroContainer, HeroWrap, ImageBg, Text, Title } from './HeroElements'

const HeroSection = () => {

    return (
        <>
            <HeroContainer id="home" >
                <HeroBg>
                    <ImageBg src="/images/Investimentos_Landing_Bg.svg" />
                </HeroBg>
                <HeroWrap>
                    <Title>Investindo <br></br>de forma facilitada</Title>
                    <Text>A Dahmer Consórcios possui mais de 20 anos de experiência no mercado e estamos preparados para te auxiliar com os seus próximos sonhos!</Text>
                </HeroWrap>
            </HeroContainer>
        </>
    )
}

export default HeroSection;
