import React, { useState } from 'react'
import Footer from '../components/base/Footer';
import Navbar from '../components/base/Navbar';
import Sidebar from '../components/base/Sidebar';

const Base = ({children, isHome = false}) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Navbar toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            {children}
            <Footer isHome={isHome} />
        </>
    )
}

export default Base
