import {db, analytics} from "./firebase";

const N_A = "Não informado";

const FORM_TEMPLATE = "home_form_mail";
const CONTEMPLADOS_TEMPLATE = "contemplados_contato_mail";

const DEFAULT_MAIL_TO = "campanhasravi@gmail.com";

export const sendEmail = () => {
    console.log("Trigger email");
}

export const sendHomeFormEmail = async (form_data) => {
    let db_data = {
        nome: form_data.nome || N_A,
        forma_contato: form_data.formaContato || N_A,
        contato_fone: form_data.contatoFone && form_data.contatoFone.length > 6? form_data.contatoFone : N_A,
        contato_email: form_data.contatoEmail || N_A,
        tipo_consorcio: form_data.tipo || N_A,
        valor: form_data.valor || N_A
    };    
    db.collection("mail").add({
        to: await get_mail_to(),
        template: {
            name: FORM_TEMPLATE,
            data: db_data
        }
    })
    .then((docRef) => {
        analytics.logEvent('Email');
        // console.log("Document written with ID: ", docRef.id);
    })
    .catch((error) => {
        // console.error("Error adding document: ", error);
    });
}

export const sendContempladosEmail = async (form_data, cartasSel) => {
    let db_data = {
        nome: form_data.nome || N_A,
        forma_contato: form_data.formaContato || N_A,
        contato_fone: form_data.contatoFone && form_data.contatoFone.length > 6? form_data.contatoFone : N_A,
        contato_email: form_data.contatoEmail || N_A,
        cartas: cartasSel
    };    
    db.collection("mail").add({
        to: await get_mail_to(),
        template: {
            name: CONTEMPLADOS_TEMPLATE,
            data: db_data
        }
    })
    .then((docRef) => {
        analytics.logEvent('Email');
        // console.log("Document written with ID: ", docRef.id);
    })
    .catch((error) => {
        // console.error("Error adding document: ", error);
    });
}

export const sendWhatsAppInteractionEmail = async () => {
    db.collection("mail").add({
        to: DEFAULT_MAIL_TO,
        message: {
            subject: 'New WhatsApp Interaction',
            html: `<b>Source:</b> ${window.location}`
        }
    });
}

const get_mail_to = async () => {
    let mail_to = DEFAULT_MAIL_TO;
    let doc = await db.collection("config").doc("mail_config").get();
    if (doc.exists) {
        mail_to = doc.data().mail_to;        
    }
    return mail_to;
}
