import React, { useState } from 'react';
import { ContatoInputBox, ProgressButton, TextCaption, TextInput } from '../FormElements';
import NavButtonsMobile from './NavButtonsMobile';

// TODO: Refactor to remove unecessary selection object
const ContactButtons = ({currentStep, selection, setSelection, progressForm, alert, returnForm}) => {

    const [phoneMask, setPhoneMask] = useState("");

    const evaluatePhoneMask = (phone) => {
        if (phone.length < 15) {
            setPhoneMask("(99) 9999-99999");
        } else {
            setPhoneMask("(99) 99999-9999");
        }
    }

    const handleSubmit = () => {
        if (isEmailFilled() || isPhoneFilled()) {
            progressForm(currentStep.type, currentStep.buttons[0]);
        } else {
            alert('Para prosseguir, favor preencher alguma forma de contato');
        }
    }

    const isEmailFilled = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return selection.contatoEmail && re.test(selection.contatoEmail.toLowerCase());;
    }

    const isPhoneFilled = () => {
        return selection.contatoFone && selection.contatoFone.length > 7;
    }

    const handleNumericChange = (e) => {
        let textValue = e.target.value;
        
        setSelection({...selection, contatoFone: textValue}); 
        evaluatePhoneMask(textValue);
    }    

    return <>
        <ContatoInputBox>
            <TextCaption>Email</TextCaption>
            <TextInput autoFocus value={selection.contatoEmail} onChange={e => {setSelection({...selection, contatoEmail: e.target.value})}} />
            <TextCaption>Telefone</TextCaption>
            <TextInput maskChar="" inputMode="numeric" mask={phoneMask} value={selection.contatoFone} onChange={handleNumericChange} />
        </ContatoInputBox>
        <ProgressButton onClickAction={() => handleSubmit()} bgColor='#247F84' nonMobile={true}>{currentStep.buttons[0].label}</ProgressButton>
        <NavButtonsMobile returnForm={returnForm} progressForm={() => handleSubmit()} invisible_back={false} invisible_progress={false} />
    </>;
};

export default ContactButtons;