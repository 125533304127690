import React from 'react'

// custom components
import { AtualizacaoDateText, AtualizacaoText, Container, HeaderAtualizacaoContainer, HeaderContainer, HeaderContent, HeaderH1, HeaderTitleContainer, HeaderCard, NavigationContent, BodyContainer, SectionText, RecordsContainer, RecordCard, BodyContent, RecordCardText, RecordHeaderText, HeaderH1Span, SomarButtonContainer, NavigationAndSomarContainer } from './ContempladoElements'
import Buttons from './Buttons';

// components
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

// icons
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import DriveEtaRoundedIcon from '@material-ui/icons/DriveEtaRounded';
import AddIcon from '@material-ui/icons/Add';

// styling
import { makeStyles } from '@material-ui/core/styles';
import Notification from './Snackbar';
import SomarCartasDialog from './SomarCartasDialog';

// integration
import { openContempladosWhatsApp } from '../../../integration/whatsapp';

const useStyles = makeStyles({
    myButton: props => ({
      background: props.backgroundColor,
      "&:hover": {
          backgroundColor: props.backgroundColor
      },
      textTransform: 'none',
      height: '48px',
      fontSize: '16px',
      minWidth: '180px'
    }),
});

const SomarCartasButton = ({
    onClick
}) => {
    const props ={ backgroundColor: '#F0803C' };
    const classes = useStyles(props);
    
    return (
        <Button
            className={classes.myButton}
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => onClick()}
        >
            Somar cartas
        </Button>
    )
}

const lastUpdateAt = "31/07/2021";

const HeroSection = ({
    contempladosState,
    setContempladosState,
    handleIndividualCheckChange,
    allChecked,
    handleAllChecked,
    onTabClick,
    tabSelected,
    onClickSomarCartas,
    filteredContemplados,
    notificationMessage,
    notificationOpen,
    setNotificationOpen,
    dialogOpen,
    handleDialogClickClose,
    openContactForm
}) => {

    const allContempladosCounter = contempladosState.contemplados.length;
    const imovelContempladosCounter = contempladosState.contemplados.filter(c => c.tipo === 'imovel').length;
    const veiculoContempladosCounter = contempladosState.contemplados.filter(c => c.tipo === 'veiculo').length;

    const onButtonsClick = (item, isWhats) => {
        isWhats ? 
            openContempladosWhatsApp([item]) :
            openContactForm([item]);
    }

    return (
        <Container id="home">
            <HeaderContainer>
                <HeaderContent>
                    <HeaderTitleContainer>
                        <HeaderH1>Cartas Contempladas</HeaderH1>
                        <HeaderAtualizacaoContainer>
                            <AtualizacaoText>Última atualização em</AtualizacaoText>
                            <AtualizacaoDateText>{lastUpdateAt}</AtualizacaoDateText>
                        </HeaderAtualizacaoContainer>
                    </HeaderTitleContainer>
                    <NavigationAndSomarContainer>
                        <NavigationContent>
                            <SectionText 
                                onClick={() => onTabClick('all')}
                                selected={tabSelected === 'all'}
                            >
                                Todas ({allContempladosCounter})
                            </SectionText>
                            <SectionText 
                                onClick={() => onTabClick('imovel')}
                                selected={tabSelected === 'imovel'}
                            >
                                Imóveis ({imovelContempladosCounter})
                            </SectionText>
                            <SectionText 
                                onClick={() => onTabClick('veiculo')}
                                selected={tabSelected === 'veiculo'}
                            >
                                Veículos ({veiculoContempladosCounter})
                            </SectionText>
                        </NavigationContent>
                        <SomarCartasButton onClick={onClickSomarCartas} />
                    </NavigationAndSomarContainer>
                </HeaderContent>
            </HeaderContainer>

            <BodyContainer>
                <BodyContent>
                    <RecordsContainer>
                        <HeaderCard>
                            <Grid 
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={6} sm={1} style={{paddingLeft: '15px'}}>
                                    <Checkbox
                                        checked={allChecked}
                                        onChange={handleAllChecked}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={1}>
                                    <RecordHeaderText style={{textAlign:"center"}}>CATEGORIA</RecordHeaderText>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <RecordHeaderText style={{textAlign:"center"}}>CRÉDITO</RecordHeaderText>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <RecordHeaderText style={{textAlign:"center"}}>ENTRADA</RecordHeaderText>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <RecordHeaderText style={{textAlign:"center"}}>PARCELA</RecordHeaderText>
                                </Grid>
                                <Grid item xs={6} sm={1}>
                                    <RecordHeaderText style={{textAlign:"center"}}>PRAZO</RecordHeaderText>
                                </Grid>
                                <Grid 
                                    item 
                                    xs={3} 
                                    sm={3} 
                                    justify="center" 
                                    container 
                                    direction="row" 
                                    // style={{paddingRight: '24px'}}
                                >
                                    <RecordHeaderText style={{textAlign:"center"}}>NEGOCIAR CARTA CONTEMPLADA</RecordHeaderText>
                                </Grid>
                            </Grid>
                        </HeaderCard>    

                        {filteredContemplados.map((contemplado) => (
                            <RecordCard>
                                <Grid 
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Grid item xs={6} sm={1} style={{paddingLeft: '15px'}}>
                                        <Checkbox
                                            checked={contemplado.checked}
                                            onChange={() => handleIndividualCheckChange(contemplado.id)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={1} style={{textAlign: 'center'}}>
                                        {contemplado.tipo === 'imovel' ? 
                                            <HomeRoundedIcon style={{ color: '#F0803C', fontSize: '40'}} />
                                            :
                                            <DriveEtaRoundedIcon style={{ color: '#F03C3C', fontSize: '40'}} />
                                        }
                                    </Grid>
                                    <Grid item xs={6} sm={2}>
                                        <RecordCardText style={{textAlign:"center"}}>R$ {contemplado.credito}</RecordCardText>
                                    </Grid>
                                    <Grid item xs={6} sm={2}>
                                        <RecordCardText style={{textAlign:"center"}}>R$ {contemplado.entrada}</RecordCardText>
                                    </Grid>
                                    <Grid item xs={6} sm={2}>
                                        <RecordCardText style={{textAlign:"center"}}>R$ {contemplado.parcela}</RecordCardText>
                                    </Grid>
                                    <Grid item xs={6} sm={1}>
                                        <RecordCardText style={{textAlign:"center"}}>{contemplado.prazo}</RecordCardText>
                                    </Grid>
                                    <Grid 
                                        item 
                                        xs={3} 
                                        sm={3} 
                                        justify="center" 
                                        container 
                                        direction="row" 
                                        // style={{paddingRight: '24px'}}
                                    >
                                        <Buttons 
                                            item={contemplado}
                                            onClick={onButtonsClick}
                                        />
                                    </Grid>
                                </Grid>
                            </RecordCard>       
                        ))}
                    </RecordsContainer>
                </BodyContent>
            </BodyContainer>
            <Notification message={notificationMessage} open={notificationOpen} setOpen={setNotificationOpen} />
            <SomarCartasDialog open={dialogOpen} onClose={handleDialogClickClose} cartas={contempladosState.contemplados} openContactForm={openContactForm} />
        </Container>
    )
}

export default HeroSection
