import React, { useState } from 'react'
import HeroSection from './HeroSection'
import { InfoContainer, InfoTitle } from '../../base/Common/FeatInfoSection/InfoElements'
import InfoSection from '../../base/Common/FeatInfoSection'
import {Section1, Section2} from './InfoSection/Data'
import FormSlider from '../../base/Common/ContactForm/FormSlider'

const ImoveisPage = () => {

    const [open, setOpen] = useState(false);

    return (
        <div id="imoveis">
            <HeroSection triggerForm={() => setOpen(true)} />
            <InfoContainer>
                <InfoTitle>Nós ajudamos você a atingir seus objetivos</InfoTitle>
                <InfoSection {...Section1}/>
                <InfoSection {...Section2}/>
            </InfoContainer>
            <FormSlider open={open} handleClose={() => setOpen(false)} initialStep={1} initialType='Imóveis' />
        </div>
    )
}

export default ImoveisPage
