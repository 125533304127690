import React from 'react'
import { ButtonDiv, MainFormDiv } from './FormElements';

import {FormData} from './FormData';

import FormHeader from './Components/FormHeader';
import NameButtons from './Components/NameButtons';
import ContactButtons from './Components/ContactButtons';
import EndButtons from './Components/EndButtons';
import SelectionButtons from './Components/SelectionButtons';
import { sendContempladosEmail, sendHomeFormEmail } from '../../../../../integration/email';

const MainForm = ({selection, setSelection, activeStep, setActiveStep, openNotification, iterateSteps, returnForm, contemplados, cards, dialogClose}) => {

    const backToStart = () => {
        if (contemplados) {
            dialogClose();
        } else {
            setSelection({...selection, tipo: null, valor: null, formaContato: null});        
            setActiveStep(0);
        }
    }

    const triggerEmail = (selection, contemplados, cards) => {
        if (contemplados) {
            sendContempladosEmail(selection, cards);
        } else {
            sendHomeFormEmail(selection);
        }
    }

    const progressForm = (type, item, value) => {       
        let steps = item.step; 
        switch (type) {
            case 'TYPE':
                setSelection({...selection, tipo: value});
                break;
            case 'VALUE':
                setSelection({...selection, valor: value});
                break;
            case 'FORMA_CONTATO':
                selection = {...selection, formaContato: value};
                setSelection(selection);
                triggerEmail(selection, contemplados, cards);
                break;
            case 'CONTATO':
                if (!selection.contatoFone || selection.contatoFone.length < 12) {
                    steps++;
                    triggerEmail(selection, contemplados, cards);
                }
                break;
            default:
                // console.log('Caí no default');
                break;
        }
        iterateSteps(steps);
    }

    let currentStep = FormData[activeStep];

    // console.log(selection);
    return (
        <MainFormDiv>
            <FormHeader currentStep={currentStep} selectedType={selection.tipo} selectedName={selection.nome} />
            <ButtonDiv>
                {currentStep.type === 'NAME'?
                    <NameButtons currentStep={currentStep} name={selection.nome} setName={(name) => setSelection({...selection, nome: name})} progressForm={progressForm} returnForm={returnForm} alert={openNotification} /> :
                currentStep.type === 'CONTATO'?
                    <ContactButtons currentStep={currentStep} selection={selection} setSelection={setSelection} progressForm={progressForm} returnForm={returnForm} alert={openNotification} /> :
                currentStep.type === 'END'?
                        <EndButtons currentStep={currentStep} selection={selection} backToStart={backToStart} contemplados={contemplados} /> :
                <SelectionButtons currentStep={currentStep} progressForm={progressForm} returnForm={returnForm} selectedType={selection.tipo} />}
            </ButtonDiv>
        </MainFormDiv>
    )
}

export default MainForm;
