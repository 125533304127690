import React from 'react';

import { HeroButton, HeroButtonRegularText, HeroButtonMobileText, SelectionButtonsWrap } from '../FormElements';
import NavButtonsMobile from './NavButtonsMobile';

const SelectionButtons = ({currentStep, progressForm, returnForm, selectedType}) => {

    const buttons = (currentStep.type === "VALUE")? currentStep.buttons[selectedType] : currentStep.buttons;

    return <SelectionButtonsWrap>
        {buttons.map((i, index) => (i.separator)? <br key={index} /> :
            <HeroButton onClickAction={() => progressForm(currentStep.type, i, i.value)} key={index}>
                <HeroButtonRegularText>{i.label}</HeroButtonRegularText>
                <HeroButtonMobileText>{i.label_mobile}</HeroButtonMobileText>
            </HeroButton>)}
        <br />
        <NavButtonsMobile returnForm={returnForm} progressForm={() => {}} invisible_back={currentStep.type === "TYPE"} invisible_progress={true} />
    </SelectionButtonsWrap>
};

export default SelectionButtons;