import { Button, makeStyles } from '@material-ui/core';
import ReactInputMask from 'react-input-mask';

import styled from 'styled-components';

export const ButtonDiv = styled.div`
    position: relative;
    width: 100%;
    text-align: center;    
    min-height: 360px;
    font-family: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
`

const useStyles = makeStyles({
    button: props => ({
        background: props.backgroundColor || '#fff',
        "text-transform": 'none',
        color: props.textColor || '#222222',
        "border-radius": props.borderRadius || '40px',
        margin: '14px',
        "min-height": '60px',
        opacity: 0.9,
        padding: '20px 52px',
        "font-size": '16px',
        "font-family": "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        "font-weight": '600',
        "&:hover": {
            "box-shadow": '3px 3px 12px #222222',
            backgroundColor: props.backgroundColor || '#fff',
            opacity: 1
        },
        "@media screen and (max-width: 1000px)": {
            display: props.nonMobile? 'none' : 'initial',
            "font-weight": '400',
            "width": '350px', 
            padding: '20px 25px'
        },
        "@media screen and (max-width: 500px)": {
            "width": '300px'
        },
        "@media screen and (max-width: 380px)": {
            "width": '70%'
        },
    })
});

export const ContatoInputBox = styled.div`
    max-width: 600px;
    display: table;
`

export const HeroButton = ({children, bgColor, onClickAction, link}) => {
    return <Button
            className={useStyles({backgroundColor: bgColor}).button}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onClickAction}
            href={link}
            target="_blank">
                <ButtonText>
                    {children}
                </ButtonText>
        </Button>
};

export const FormHeaderDiv = styled.div`
    padding-top: 80px;
    min-height: 290px;
    display: flex;
    justify-content: center;
    align-items: center; 

    @media screen and (max-width: 1200px) {
        padding-top: 0px;
        min-height: 300px;
    }
    
    @media screen and (max-width: 1000px) {
        min-height: 160px;
    }
`

export const FormFinalHeaderDiv = styled.div`
    padding-top: 50px;
`

export const HeroButtonRegularText = styled.div`
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

export const HeroButtonMobileText = styled.div`
    display: none;
    @media screen and (max-width: 1000px) {
        display: inherit;
    }
`

export const IconDiv = styled.div`
    text-align: center;
    min-height: 80px;
`

export const FormIcon = styled.img`
    height: 70px;
`

export const FormH1 = styled.h1`
    color: #FFFFFF;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    max-width: 734px;
    margin: auto;
    vertical-align: middle;

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    @media screen and (max-width: 1000px) {
        font-size: 32px;
        font-weight: 400;
        max-width: 500px;
    }

    @media screen and (max-width: 750px) {
        font-size: 28px;
        max-width: 360px;
    }
`;

export const SelectionButtonsWrap = styled.div`
    padding-top: 34px;
    @media screen and (max-width: 1000px) {
        max-width: 500px;        
        margin: 0 auto;
    }
`

export const FormH1Div = styled.div`
    height: 100px;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align:center;
    

    @media screen and (max-width: 1000px) {
        height: unset;
    }
`

export const NameHighlight = styled.span`
    color: #247F84;
`

export const ProgressButton = ({children, bgColor, onClickAction, nonMobile}) => {
    return <Button
            id='progressButton'
            className={useStyles({backgroundColor: bgColor, borderRadius: '8px', textColor: '#ffffff', nonMobile: nonMobile}).button}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onClickAction}
            target="_blank">
                <ButtonText>
                    {children}
                </ButtonText>
        </Button>
};

export const ProgressButtonMobile = ({children, bgColor, onClickAction, nonMobile}) => {
    return <Button
            id='progressButton'
            className={useStyles({backgroundColor: bgColor, borderRadius: '8px', textColor: '#ffffff', nonMobile: nonMobile}).button}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onClickAction}
            target="_blank">
                <ButtonText>
                    {children}
                </ButtonText>
        </Button>
};

export const TextInput = styled(ReactInputMask)`
    width: 476px;
    height: 60px;
    box-shadow: 3px 3px 12px #00000029;
    border-radius: 40px;
    border-width: 0px;
    padding: 20px;
    color: #222222;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 14px 0px 10px 0px;

    :focus {
        outline: none;
    }
    
    @media screen and (max-width: 1200px) {
        width: 420px;
    }
    
    @media screen and (max-width: 1000px) {
        margin: 30px;
    }
    
    @media screen and (max-width: 500px) {
        width: 300px;
    }
`

export const TextCaption = styled.div`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
`

export const NameButtonsWrap = styled.div`
    padding-top: 34px;
    // padding: 34px 0px 118px 0px;
` 

// Themes

export const progressBarTheme = makeStyles({
    root: props => ({
        background: '#00000000',
        visibility: props.visible? 'visible' : 'hidden',
        "justify-content": 'center',
        "& .MuiLinearProgress-colorPrimary": {
            backgroundColor: "#9DC0C2",
            "border-radius": '12px'
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#247F84",
            "border-radius": '12px'
        },
        "& .MuiMobileStepper-progress": {
            height: '12px',
            width: '305px'
        },
        "& .MuiButtonBase-root.Mui-disabled": {
            display: 'none'
        },
        "@media screen and (max-width: 1000px)": {
            display: 'none'
        }
    }),
});    

export const formNavigationStyle = makeStyles({
    button: props => ({
      background: "#247F84",
      color: '#fff',
      "border-radius": '8px',
      padding: '4px 10px',
      "font-size": '10px',
      "margin-right": '14px',
      opacity: 0.9,
      "&:hover": {
        "box-shadow": '3px 3px 12px #222222',
        backgroundColor: "#247F84",
        opacity: 1
      },
      display: props.disabled? 'none' : 'flex'
    }),
    button_mobile: props => ({
        background:'#247F84',
        color: '#fff',
        float: props.float? props.float : 'initial',
        textTransform: 'none',
        "border-radius": '8px',
        padding: '5px 12px',
        "font-size": '12px',
        opacity: 0.9,
        "&:hover": {
          "box-shadow": '3px 3px 12px #222222',
          backgroundColor: '#247F84',
          opacity: 1
        },
        visibility: props.disabled? 'hidden' : 'initial'
      }),
});

export const MainFormDiv = styled.div`
    min-height: 600px;

    @media screen and (max-width: 1720px) {
        min-height: 650px;
    }
    
    @media screen and (max-width: 1720px) {
        min-height: 650px;
    }
    
`

export const NavButtonsMobileWrap = styled.div`
    display: none;
    @media screen and (max-width: 1000px) {
        display: initial;
    }
`

export const FormFinalTitle = styled.h1`
    color: #FFFFFF;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    max-width: 520px;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    

    @media screen and (max-width: 850px) {
        font-weight: 600;
        font-size: 40px;        
        max-width: 400px;
        padding-top: 50px;
    }
    
    @media screen and (max-width: 500px) {
        font-size: 28px;        
        max-width: 236px;
    }
`

export const FormFinalH1 = styled.h1`
    color: #FFFFFF;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    max-width: 850px;
    margin: 10px auto;
    text-shadow: 0.5px 0.5px #010101;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
    @media screen and (max-width: 850px) {
        max-width: 78%;
        font-size: 20px;
        font-weight: 600;
    }
    
    @media screen and (max-width: 500px) {
        font-size: 16px;
    }
`

export const FormFinalSubtitle = styled.div`
    padding: 32px 0px;
`

export const NormalText = styled.span`
    font-weight: normal;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`

export const ButtonText = styled.span`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`
