import React from 'react'
import Base from './base';
import ContempladosPage from '../components/pages/contemplados';

const Contemplados = () => {

    return (
        <Base>
            <ContempladosPage />            
        </Base>
    )
}

export default Contemplados
