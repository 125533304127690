import './App.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages/home';
import Imoveis from './pages/imoveis';
import Veiculos from './pages/veiculos';
import Investimentos from './pages/investimentos';
import ScrollToTop from './ScrollToTop.js'
import Contemplados from './pages/contemplados';
import Empresa from './pages/empresa';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/imoveis" component={Imoveis} exact />
          <Route path="/veiculos" component={Veiculos} exact />
          <Route path="/investimentos" component={Investimentos} exact />
          <Route path="/contemplados" component={Contemplados} exact />
          <Route path="/empresa" component={Empresa} exact />
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
