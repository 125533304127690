import React from 'react';
import { HeroContainer, HeroBg, HeroButton, ImageBg, FormContent, FormWrap, HeroH1, HeroH2, HeroSpan, HeroWrap } from '../../../../components/base/Common/FeatHeroSection/HeroElements';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import InvestimentosIcon from '@material-ui/icons/InsertChart';
import { openWhatsApp } from '../../../integration/whatsapp';

const Image = '/images/imoveis-hero-bg.jpg';

const HeroSection = ({triggerForm}) => {
    // TODO: Email button should open modal form
    return (
        <HeroContainer id="home">
            <HeroBg>
                <ImageBg src={Image} />
            </HeroBg>
            <HeroWrap>
                <HeroH1>Realize o seu sonho da <HeroSpan>casa própria</HeroSpan></HeroH1>
                <HeroH2>Saia do aluguel com parcelas a partir de R$ 273,20 mensais e realize o seu sonho da casa própria.</HeroH2>
                <HeroH2>Entre em contato conosco e nós vamos te ajudar!</HeroH2>
                <FormWrap>
                    <FormContent>
                        <HeroButton bgColor="#F39468" icon={<WhatsAppIcon />} onClickAction={() => openWhatsApp({tipo: 'Imóveis'})}>Enviar Whats</HeroButton>
                        <HeroButton bgColor="#DF6E3A" icon={<EmailIcon />} onClickAction={triggerForm}>Enviar Email</HeroButton>
                        {/* <HeroButton bgColor="#B15023" icon={<InvestimentosIcon />}>Simulador</HeroButton> */}
                    </FormContent>
                </FormWrap>
            </HeroWrap>            
        </HeroContainer>
    )
}

export default HeroSection
