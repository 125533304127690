const DEFAULT_FORM_VALUES = [
    {
        label: 'Até R$ 150.000,00',
        label_mobile: 'Até R$ 150.000,00',
        value: 'Até R$ 150.000,00',
        step: 1
    },
    {
        label: 'Entre R$ 150.000,00 e R$ 300.000,00',
        label_mobile: 'Entre R$ 150.000,00 e R$ 300.000,00',
        value: 'Entre R$ 150.000,00 e R$ 300.000,00',
        step: 1
    },
    {
        label: 'Entre R$ 300.000,00 e R$ 500.000,00',
        label_mobile: 'Entre R$ 300.000,00 e R$ 500.000,00',
        value: 'Entre R$ 300.000,00 e R$ 500.000,00',
        step: 1
    },
    {
        label: 'Mais de R$ 500.000,00',
        label_mobile: 'Mais de R$ 500.000,00',
        value: 'Mais de R$ 500.000,00',
        step: 1
    },
    {
        label: 'Ainda não sei o valor que preciso',
        label_mobile: 'Ainda não sei o valor que preciso',
        value: 'Ainda não sei o valor que preciso',
        step: 1
    }
];

export const FormData = [
    {
        headline: 'Como nós podemos te ajudar a transformar seu sonho em realidade?',
        type: 'TYPE',
        hideIcon: true,
        buttons: [
            {
                label: 'Eu quero fazer um consórcio de Imóvel',
                label_mobile: 'Consórcio de Imóvel',
                value: 'Imóveis',
                step: 1
            },
            {
                label: 'Eu quero fazer um consórcio de Veículo',
                label_mobile: 'Consórcio de Veículo',
                value: 'Veículos',
                step: 1
            },
            {
                separator: true
            },
            {
                label: 'Eu quero usar o consórcio como Investimento',
                label_mobile: 'Investir com Consórcio',
                value: 'Investimento',
                step: 1
            },
            {
                label: 'Estou buscando mais informações sobre Consórcios',
                label_mobile: 'Estou buscando mais informações sobre Consórcios',
                value: 'Informações',
                step: 2
            }
        ]
    },
    {
        headline: 'Qual valor você precisa?',
        type: 'VALUE',
        buttons: {
            "Imóveis": DEFAULT_FORM_VALUES,
            "Investimento": DEFAULT_FORM_VALUES,
            "Veículos": [
                {
                    label: 'Entre R$ 20.000,00 e R$ 50.000,00',
                    label_mobile: 'Entre R$ 20.000,00 e R$ 50.000,00',
                    value: 'Entre R$ 20.000,00 e R$ 50.000,00',
                    step: 1
                },
                {
                    label: 'Entre R$ 50.000,00 e R$ 100.000,00',
                    label_mobile: 'Entre R$ 50.000,00 e R$ 100.000,00',
                    value: 'Entre R$ 50.000,00 e R$ 100.000,00',
                    step: 1
                },
                {
                    label: 'Entre R$ 100.000,00 e R$ 200.000,00',
                    label_mobile: 'Entre R$ 100.000,00 e R$ 200.000,00',
                    value: 'Entre R$ 100.000,00 e R$ 200.000,00',
                    step: 1
                },
                {
                    label: 'Mais de R$ 200.000,00',
                    label_mobile: 'Mais de R$ 200.000,00',
                    value: 'Mais de R$ 200.000,00',
                    step: 1
                },
                {
                    label: 'Ainda não sei o valor que preciso',
                    label_mobile: 'Ainda não sei o valor que preciso',
                    value: 'Ainda não sei o valor que preciso',
                    step: 1
                }
            ]
        }
    },
    {
        headline: 'Qual o seu nome?',
        type: 'NAME',
        buttons: [
            {
                label: 'AVANÇAR',
                step: 1
            }
        ]
    },    
    {
        headline: 'Contato',
        type: 'CONTATO',
        buttons: [
            {
                label: 'AVANÇAR',
                step: 1
            }
        ]
    },
    {
        headline: 'Qual a melhor forma para entrarmos em contato, ${NOME}?',
        type: 'FORMA_CONTATO',
        buttons: [
            {
                label: 'WhatsApp',
                label_mobile: 'WhatsApp',
                value: 'WhatsApp',
                step: 1
            },
            {
                separator: true
            },
            {
                label: 'Ligação',
                label_mobile: 'Ligação',
                value: 'Ligação',
                step: 1
            },
            {
                label: 'Email',
                label_mobile: 'Email',
                value: 'Email',
                step: 1
            }
        ]
    },
    {
        headline: "Contatos:", // TODO: Remove
        title: 'Agradecemos pela confiança!',
        header1: 'Se você quiser entrar em contato conosco agora mesmo, fique à vontade!',
        header2: 'Aqui está o nosso contato:',
        type: 'END',
        hideIcon: true,
        buttons: [
            {
                label: 'Dirzenio A. Dahmer',
                tel: '5551999888575'
            }
        ],
        progress_label: 'VOLTAR AO INÍCIO'
    },
]
