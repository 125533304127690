import React from 'react'
import Base from './base';
import InvestimentosPage from '../components/pages/investimentos';

const Investimentos = () => {

    return (
        <Base>
            <InvestimentosPage />            
        </Base>
    )
}

export default Investimentos
