import React from 'react';

import { AppBar, Dialog, IconButton, Slide, Toolbar } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import HeroSection from './HeroSection';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const FormSlider = ({open, handleClose, initialStep, initialType, contemplados, cards}) => {    
    return <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar style={{ background: '#20343B' }} > 
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <HeroSection initialStep={initialStep} initialType={initialType} contemplados={contemplados} cards={cards} dialogClose={handleClose} />
        </Dialog>;
}

export default FormSlider;