import styled from 'styled-components';


export const InfoTitle = styled.h1`
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    font: normal normal 600 38px/60px Segoe UI;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    color: #383838;
    padding-top: 95px;
    padding-bottom: 70px;

    @media screen and (max-width: 1200px) {
        font-size: 30px;
        padding-top: 70px;
        padding-bottom: 55px;
    }

    @media screen and (max-width: 479px) {
        font-size: 28px;
        font-weight: 500;
        padding: 32px 32px 32px 32px;
        line-height: 36px;
    }
`

export const InfoContainer = styled.div`
    align-items: center;
    justify-items: center;
    background-color: #F6F6F6;
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    padding: 0 24px;

    @media screen and (max-width: 1200px) {
        min-height: 1200px;
    }

    @media screen and (max-width: 479px) {
        padding: 0;
        min-height: unset;
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    padding-bottom: 72px;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1 col2'` : `'col2 col1'`)};

    @media screen and (max-width: 1200px) {
        grid-template-areas: 'col1' 'col2';
        padding-bottom: 50px;
    }

    @media screen and (max-width: 479px) {
        grid-template-areas: 'col1' 'col2';
        padding-bottom: 0;
    }
`;

export const ArgumentNumber = styled.h1`
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 0px;
    font-weight: 300;
    color: #060606;

    @media screen and (max-width: 479px) {
        font-size: 14px;
    }
`

export const Column1 = styled.div`
    grid-area: col1;
    margin: auto;

    @media screen and (max-width: 500px) {
        width: 100%;
        min-width: 290px;
    }
`;

export const Column2 = styled.div`
    padding-left: ${({imgStart}) => (imgStart ? '0px' : '200px')};
    grid-area: col2;
    position: relative;
    margin: auto;

    @media screen and (max-width: 1200px) {
        padding-left: 0px;
        min-width: 500px;
        width: 60%;
    }

    @media screen and (max-width: 479px) {
        width: 100%;
        min-width: 290px;
    }
`;

export const TextWrapper = styled.div`
    max-width: 725px;
    padding-top: 0;
    padding-bottom: 30px;

    @media screen and (max-width: 479px) {
        margin: 0 32px 0 32px;
        padding-bottom: 32px;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 1.0;
    font-weight: 600;
    color: #060606;
    text-align: left;

    @media screen and (max-width: 479px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
    }
`;

export const Subtitle = styled.p`
    font-size: 18px;
    line-height: 32px;
    text-align: left;
    color: #060606;
    width: 70%;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    @media screen and (max-width: 479px) {
        font-size: 14px;
        line-height: 24px;
    }
`;

export const ImgWrap = styled.div`
    max-width: 725px;
    height: 100%;
    position: relative;
    z-index: 10;
    text-align: ${({imgStart}) => (imgStart ? 'left' : 'right')};

    @media screen and (max-width: 1200px) {
        padding-bottom: 80px;
    }

    @media screen and (max-width: 479px) {
        padding-bottom: 32px;
    }
`;

export const Img = styled.img`
    max-width: 500px;
    padding-right: 0;
    box-shadow: 0px 8px 40px #0000004D;
    border-radius: 10px;
    opacity: 1;
    z-index: 10;

    @media screen and (max-width: 479px) {
        width: 100%;
        box-shadow: unset;
        border-radius: unset;
        max-width: unset;
    }
`;

export const ImageBackgroundShape = styled.div`
    border-radius: ${({borderRadius}) => (borderRadius)};
    height: 100%;
    width: 725px;
    background: ${({bgColor}) => (bgColor)};
    position: absolute;
    bottom: -70px;
    right: ${({imgStart}) => (imgStart ? '-60px' : '80px')};
    z-index: 2;

    @media screen and (max-width: 1600px) {
        display: none;
    }
`;
