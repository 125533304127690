import React, {useState, useEffect} from 'react';
import { HeroContainer, HeroBg, HeroButton, ImageBg, FormContent, FormWrap, HeroH1, HeroH2, HeroSpan, HeroWrap, NewlineText, ScrimBg } from '../../../../components/base/Common/FeatHeroSection/HeroElements';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import { openWhatsApp } from '../../../integration/whatsapp';
import BgSelector from './BgSelector';

import {Carro, Moto, Utilitario} from './BgSelectorData';

const IMAGE_BG_TIMEOUT_MS = 10000;

const HeroSection = ({triggerForm}) => {

    const[images, setImages] = useState({selected: Carro, top: Utilitario, bottom: Moto});
    const[clicked, setClicked] = useState(false);
    
    const[toggle, setToggle] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!clicked) {
                switch (toggle) {
                    case 0:
                        setImages({selected: Utilitario, top: Moto, bottom: Carro});
                        break;
                    case 1:                        
                        setImages({selected: Moto, top: Carro, bottom: Utilitario});
                        break;
                    case 2:
                        setImages({selected: Carro, top: Utilitario, bottom: Moto});                        
                        break;
                
                    default:
                        break;                    
                }
                setToggle((toggle + 1) % 3);
            }
        }, IMAGE_BG_TIMEOUT_MS);
        return () => clearInterval(interval);
      }, [clicked, toggle]);

    // TODO: Email button should open modal form
    return (
        <HeroContainer id="home">
            <HeroBg>                
                {[Utilitario, Carro, Moto].map(i => {
                    return <ImageBg src={i.img} op={(images.selected === i)? 1 : 0}  />
                })}
                <ScrimBg />
            </HeroBg>
            <BgSelector images={images} setImages={setImages} setClicked={setClicked} />
            <HeroWrap>
                <HeroH1>Qual a <HeroSpan>sua necessidade</HeroSpan> de veículos?</HeroH1>
                <HeroH2><NewlineText text={images.selected.text} /></HeroH2>                
                <FormWrap>
                    <FormContent>
                        <HeroButton bgColor={images.selected.buttonColors[0]} icon={<WhatsAppIcon />} onClickAction={() => openWhatsApp({tipo: 'Veículos'})}>Enviar Whats</HeroButton>
                        <HeroButton bgColor={images.selected.buttonColors[1]} icon={<EmailIcon />} onClickAction={triggerForm}>Enviar Email</HeroButton>
                    </FormContent>
                </FormWrap>
            </HeroWrap>            
        </HeroContainer>
    )
}

export default HeroSection
