import { Button, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const HeroBg = styled.div`
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 2;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 3;
    
`;

export const HeroWrap = styled.div`
    position: relative;
    padding-top: 100px;
    margin: 0 auto;    
    z-index: 4;

    @media screen and (max-width: 500px) {
        padding-top: 75px;
    }

`;

export const FormWrap = styled.div`
    position: absolute;    
    z-index: 4;
    height: 100%;
    width: 100%;
`

export const LogoImg = styled.img`
    width: 124px;
    height: 124px;
`

export const HeroContainer = styled.div`
    height: 960px;
    position: relative;
    z-index: 1;
    display: flex;

    @media screen and (max-width: 1200px) {
        height: 850px;
    }
`;

export const RightSideWrap = styled.div`
    width: 38%;
    height: 100%;
    position: relative;
    display: inline-block;
`
export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
`

export const FormPanel = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`

export const LeftPanel = styled.div`
    min-width: 35%;
    opacity: 1;
    height: 100%;
    position: relative;
    z-index: 3;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    text-align: -webkit-center;
    padding-top: 250px;

    @-moz-document url-prefix() {
        text-align: -moz-center;
    }

    @media screen and (max-width: 1400px) {
        display: none;
    }
`;

export const TitleLineDiv = styled.div`
    padding: 50px 0px;
`

export const TitleLine = styled.hr`
    width: 426px;
    border-color: #9DC0C2;
    height: 0px;
`

export const LeftPanelContent = styled.div`
    width: 426px;
`

export const HeroSubtitle = styled.h1`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 24px;
    color: #fff;
    letter-spacing: 0.96px;
`

export const SubtitleHighlight = styled.span`
    color: #31BCA1;
`

export const HeroTitle = styled.h1`
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #fff;
    font-size: 64px;
    font-weight: bold;
    padding-top: 36px;
`

export const FormContent = styled.div`
    height: 100%;

    @media screen and (max-width: 479px) {
        padding: 10px;
    }
`;

export const ImageTextWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    text-align: center;
    width: 75%;
`;

export const ImageTitle = styled.h1`
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 36px;
    color: #2f2f2f;
`;

export const ImageSpan = styled.span`
    color: #FF0000;
`;

export const ImageText = styled.p`
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    color: #2f2f2f;
`

export const FormH2 = styled.h2`
    color: #2f2f2f;
    font-size: 18px;
    font-weight: 200;
    text-align: center;
    max-width: 520px;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 28px;
`;

export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: #fff;
    font-size: 14px;
`;

export const FormRectangle = styled.div`
    max-width: 730px;
    height: 100px;
    background: #fff;
    box-shadow: 3px 3px 12px #0000004D;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 24px;
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        transition: all 0.2s ease-in-out;
        box-shadow: 6px 6px 18px #0000004D;
    }
`;

export const TextAndIconRectangle = styled.div`
    max-width: 100%;
    height: 100%;
    display: flex;
`;

export const RectangleInnerCircle = styled.span`
    height: 48px;
    width: 48px;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 28px 32px;
`;

export const RectangleText = styled.p`
    text-align: center;
    color: #3A3A3A;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin: auto 0px;
`;

export const Footer = styled.div`
    width: 730px;
    display: flex;
    margin: 0 auto;
    margin-top: 32px;
    justify-content: ${({spaceBetween}) => (spaceBetween)};
`

export const StepText = styled.p`
    text-align: end;
    color: #858585;
    font-size: 14px;
`;

export const VoltarWrap = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({marginBottom}) => (marginBottom)}
`

export const VoltarText = styled.p`
    text-align: start;
    color: #858585;
    font-size: 14px;
    cursor: pointer;
`;

export const StepTextSpan = styled.span`
    color: #FF0000;
`;

export const FormLine = styled.div`
    border: 1px solid #BFBFBF;
    max-width: 720px;
    display: flex;
    margin: 0 auto;
`;