import React from 'react';
import { Card, CardColumn, CardInfoCaption, CardInfoHeader, CardInfoSub, CardTable, ComparisonBg, ComparisonContent, ComparisonInfoH1, ComparisonInfoSubtitle, ComparisonWrap, Header, HeaderContent, HeaderH1, HeaderSubtitle, ImageBg, Img } from './ComparisonElements';
import {ConsorcioRow, FinanciamentoRow, ResultsRow} from './Data';

const Background = '/images/home-comparison-bg.jpg';

const ComparisonSection = () => {
    return  <>
                <Header>
                    <HeaderContent>
                        <HeaderH1>CONSÓRCIO ou FINANCIAMENTO</HeaderH1>
                        <HeaderSubtitle>Caso você ainda esteja em dúvida sobre porque cada vez mais as pessoas escolhem o consórcio como o seu próximo investimento e porque você também deve seguir por esse caminho, dê uma olhada no comparativo que montamos abaixo entre o Consórcio e o Financiamento.</HeaderSubtitle>
                    </HeaderContent>                    
                </Header>
                <ComparisonContent>
                    <ComparisonBg>
                        <ImageBg src={Background} />
                    </ComparisonBg>
                    <ComparisonWrap>
                        <ComparisonRow {...FinanciamentoRow} />
                        <ComparisonRow {...ConsorcioRow} />                        
                        <ComparisonRow {...ResultsRow} />
                    </ComparisonWrap>
                </ComparisonContent>
            </>
}

const ComparisonRow = ({
    header,
    subtitle,
    image1,
    image2,
    image3,
    infoHeader1,
    infoHeader2,
    infoHeader3,
    infoSub1,
    infoSub2,
    infoSub3,
    infoCaption1,
    infoCaption2,
    infoCaption3,
    focus
}) => {
    let cardFocus = focus || [false, false, false];    
    return <>
        <ComparisonInfoH1>{header}</ComparisonInfoH1>
        <ComparisonInfoSubtitle>{subtitle}</ComparisonInfoSubtitle>
        <CardTable>
            <ComparisonCard column={1} image={image1} cardFocus={cardFocus[0]} infoHeader={infoHeader1} infoSub={infoSub1} infoCaption={infoCaption1} />
            <ComparisonCard column={2} image={image2} cardFocus={cardFocus[1]} infoHeader={infoHeader2} infoSub={infoSub2} infoCaption={infoCaption2} />
            <ComparisonCard column={3} image={image3} cardFocus={cardFocus[2]} infoHeader={infoHeader3} infoSub={infoSub3} infoCaption={infoCaption3} />
        </CardTable>
    </>
}

const ComparisonCard = ({column, image, cardFocus, infoHeader, infoSub, infoCaption}) => {
    return <CardColumn column={column} cardFocus={cardFocus}>
        <Img src={image} cardFocus={cardFocus} />
        <Card cardFocus={cardFocus}>
            <CardInfoHeader cardFocus={cardFocus} >{infoHeader}</CardInfoHeader>
            <CardInfoSub cardFocus={cardFocus}>{infoSub}</CardInfoSub>
            <CardInfoCaption>{infoCaption}</CardInfoCaption>
        </Card>
    </CardColumn>
}

export default ComparisonSection;