import styled from 'styled-components';

export const TriangleWrapper = styled.div`
    width: 323px;
    height: 255px;
    position: absolute;
    left: ${({left}) => left};
    bottom: ${({bottom}) => bottom};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 12;

    @media screen and (max-width: 1630px) {
        display: none;
    }
`;

export const TriangleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Triangle = styled.div`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid;
    color: ${({color}) => color};
`;
