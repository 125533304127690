import React, { useEffect, useState } from 'react'
import BeneficiosSection from './BeneficiosSection';
import HeroSection from './HeroSection'
import InfoSection from './InfoSection'
import ExemploSection from './ExemploSection'
import {investimentosSectionAluguel, investimentosSectionRenda, investimentosSectionSeguro} from './InfoSection/Data';
import ExemploSectionMobile from './ExemploSection/Mobile';

const InvestimentosPage = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 480;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <div id="investimentos">
            <HeroSection />
            <InfoSection {...investimentosSectionRenda} />
            <InfoSection {...investimentosSectionAluguel} />
            <InfoSection {...investimentosSectionSeguro} />
            {width < breakpoint ? 
                <ExemploSectionMobile />
                :
                <ExemploSection />
            }
            <BeneficiosSection width={width} breakpoint={breakpoint} />
        </div>
    )
}

export default InvestimentosPage
