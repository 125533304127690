import React from 'react';
import { Background, BigText, BigTextContainer, BigTextSub, Card, CardsContainer, CardText, CardTitle, Container, ContentContainer, ImageBg, ScrimBg, Text, Title, TitleContainer } from './ExemploElements';

const ExemploSection = () => {
    return (
        <Container>
            <Background>
                <ImageBg src="/images/exemplo_investimento.jpg"/>
                <ScrimBg />
            </Background>
            <ContentContainer>
                <TitleContainer>
                    <Title>Investimento na prática</Title>
                    <Text>Você começa adquirindo uma ou mais cotas de consórcios e pagando somente metade do valor da parcela até a contemplação. Até ser contemplado, seu crédito é reajustado pelo INCC, o que lhe garante todos os anos um poder maior de venda e lucro!
                        <br></br><br></br>Em seguida você encontra um exemplo com valores reais de um consórcio em que é considerado uma contemplação no 18º mês. Lembre-se que no consórcio não há como garantir o mês da contemplação e isso é somente uma simulação!
                    </Text>
                </TitleContainer>
                <BigTextContainer>
                    <BigTextSub>LUCRO</BigTextSub>
                    <BigText>R$ 25.779,50</BigText>
                </BigTextContainer>
                <CardsContainer>
                    <Card>
                        <CardTitle>VALOR DO CRÉDITO</CardTitle>
                        <CardText>R$ 150.000,00</CardText>
                    </Card>
                    <Card>
                        <CardTitle>VALOR DAS PARCELAS</CardTitle>
                        <CardText>R$ 512,25</CardText>
                    </Card>
                    <Card>
                        <CardTitle>VALOR INVESTIDO</CardTitle>
                        <CardText>R$ 9.220,50</CardText>
                    </Card>
                    <Card>
                        <CardTitle>VALOR DA VENDA</CardTitle>
                        <CardText>R$ 35.000,00</CardText>
                    </Card>
                </CardsContainer>
            </ContentContainer>
        </Container>
    )
}

export default ExemploSection;