import React from 'react';
import { FormFinalH1, FormFinalHeaderDiv, FormFinalSubtitle, FormFinalTitle, FormH1, FormH1Div, FormHeaderDiv, FormIcon, IconDiv, NameHighlight } from '../FormElements';

const IconHome = '/images/icon-home.png';
const IconCar = '/images/icon-car.png';
const IconMoney = '/images/icon-money.png';
const IconInfo = '/images/icon-info.png';

const FormHeader = ({currentStep, selectedType, selectedName}) => {

    const renderIcon = () => {
        switch (selectedType) {
            case 'Imóveis':
                return IconHome;
            case 'Veículos':
                return IconCar;
            case 'Investimento':
                return IconMoney;
            case 'Informações':
                return IconInfo;
            default:
                return '';
        }
    }
    
    // Known limitation: single variable per header
    const replaceAndHighlightVariable = (text) => {
        let term = '${NOME}';
        var parts = text.split(term);

        if (parts.length > 1) {
            return <>
                {parts[0]}<NameHighlight>{selectedName}</NameHighlight>{parts[1]}
            </>;
        } else {
            return <>{text}</>;
        }
    }

    return <>
    {currentStep.type !== 'END'?
            <FormHeaderDiv>
                <div>
                    {!currentStep.hideIcon? <IconDiv><FormIcon src={renderIcon()} /></IconDiv> : <></>}
                    <FormH1Div><FormH1>{replaceAndHighlightVariable(currentStep.headline)}</FormH1></FormH1Div>
                </div>
            </FormHeaderDiv> :
            <FormFinalHeaderDiv>
                <FormFinalTitle>{currentStep.title}</FormFinalTitle>
                <FormFinalSubtitle>
                    <FormFinalH1>{currentStep.header1}</FormFinalH1>
                    <FormFinalH1>{currentStep.header2}</FormFinalH1>
                </FormFinalSubtitle>
            </FormFinalHeaderDiv>
            }
    </>;
};

export default FormHeader;