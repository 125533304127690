export const investimentosSectionRenda = {
    id: 'renda',
    bgColor: '#fff',
    headline: 'Oportunidade de Renda extra',
    description: 'Com a negociação da carta de crédito você pode gerar lucros. Além disso, como o consórcio não possui juros, você não compromete seu orçamento.',
    buttonBg: '#D17817',
    imgStart: true,
    img: "/images/renda_extra.jpg",
    alt: 'renda',
    imageBackgroundColor: '#F3D7B8',
    imageBgBorderRadius: '40px 40px 40px 40px',
    btnTextColor: '#fff',
    mobileBgColor: '#FFECC9',
    mobileTextColor: '#2f22f',
    buttonMobileBg: '#D17817',
};

export const investimentosSectionAluguel = {
    id: 'veiculos',
    bgColor: '#fff',
    headline: 'Investir com aluguel de Imóveis',
    description: 'Invista através da compra de imóveis com a sua carta de crédito e use o aluguel do imóvel para quitar as suas parcelas do consórcio.',
    buttonBg: '#082321',
    imgStart: false,
    img: "/images/aluguel.jpg",
    alt: 'aluguel',
    imageBackgroundColor: '#082321',
    imageBgBorderRadius: '40px 40px 40px 40px',
    btnTextColor: '#fff',
    mobileBgColor: '#082321',
    mobileTextColor: '#fff',
    buttonMobileBg: '#15645E',
};

export const investimentosSectionSeguro = {
    id: 'investimentos',
    bgColor: '#fff',
    headline: 'Seguro, Econômico e Facilitado',
    description: 'O Consórcio é uma aplicação mais segura que o mercado variável, uma vez que, como investidor, você escolhe como e quando usar a sua carta de crédito.',
    buttonBg: '#D1585D',
    imgStart: true,
    img: "/images/seguro.jpg",
    alt: 'seguro',
    imageBackgroundColor: '#D1585D',
    imageBgBorderRadius: '40px 40px 40px 40px',
    btnTextColor: '#fff',
    mobileBgColor: '#D1585D',
    mobileTextColor: '#fff',
    buttonMobileBg: '#971B20',
};