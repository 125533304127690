import styled from 'styled-components';

export const HeroContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: flex-start;
    height: 1080px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 479px) {
        height: 667px;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 2;
    height: 100%;
    overflow: hidden;
    z-index: 3;
`;

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
`;

export const HeroWrap = styled.div`
    height: 100%;
    width: 100%;
    z-index: 4;

    @media screen and (max-width: 479px) {
        margin: 0 32px 0 32px;
    }
`;

export const Title = styled.h1`
    font-size: 80px;
    text-align: start;
    color: #B31923;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    margin: 350px 0 0 14.58%;

    @media screen and (max-width: 479px) {
        font-size: 32px;
        margin: 180px 0 0 0;
    }
`;

export const Text = styled.p`
    font-size: 20px;
    text-align: start;
    color: #6B050B;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: normal;
    margin: 24px 0 0 14.58%;
    max-width: 550px;
    line-height: 36px;

    @media screen and (max-width: 479px) {
        font-size: 16px;
        margin: 48px 0 0 0px;
        font-weight: 400;
        line-height: 24px;
    }
`;