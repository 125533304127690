export const homeSectionImoveis = {
    id: 'imoveis',
    id_alt: 'Imóveis',
    bgColor: '#F5FAFF',
    headline: 'Compre ou construa o Imóvel dos seus sonhos',
    description: 'Se você está planejando o seu futuro e está em busca da realização do seu sonho da casa própria, o consórcio pode te ajudar a alcançar seu objetivo.',
    description2: 'E não para por aí, você não precisa necessariamente adquirir um imóvel se não é esse seu objetivo final. O consórcio, diferente de outros financiamentos, é altamente flexível e lhe permite tomar a ação que mais faz sentido para a sua vida. Seja comprar, construir ou reformar, estamos preparados para te ajudar.',
    description3: 'Entre em contato com a Dahmer Consórcios e ficaremos felizes em te explicar mais do porque o Consórcio pode mudar a sua vida!',
    buttonBg: '#4694DB',
    imgStart: true,
    img: "/images/house.jpg",
    alt: 'House',
    imageBackgroundColor: '#A1C4E8',
    imageBgBorderRadius: '40px 40px 40px 40px',
    valorDasParcelas: 'R$ 273,20',
    fraseDasParcelas: 'Você investindo no seu sonho de forma simples e segura!',
};

export const homeSectionVeiculos = {
    id: 'veiculos',
    id_alt: 'Veículos',
    bgColor: '#fff',
    headline: 'Pensando em comprar o primeiro veículo?',
    description: 'Se você está precisando renovar a sua frota de veículos, pensando em começar uma ou ainda em busca do seu primeiro automóvel, fique à vontade para entrar em contato com nós da Dahmer Consórcios!',
    description2: 'Possuímos mais de 20 anos de experiência no mercado de consórcios e vamos oferecer um atendimento customizado para você!',
    buttonBg: '#A0383F',
    imgStart: false,
    img: "/images/car.jpg",
    alt: 'Car',
    imageBackgroundColor: '#E69398',
    imageBgBorderRadius: '40px 40px 40px 40px',
    valorDasParcelas: 'R$ 197,20',
    fraseDasParcelas: 'Você de carro novo e com toda a segurança da HS Consórcios!',
};

export const homeSectionInvestimentos = {
    id: 'investimentos',
    id_alt: 'Investimento',
    bgColor: '#FFF7E9',
    headline: 'Investimentos seguros e facilitados',
    description: 'Investir em Consórcio é sinônimo de Segurança, Economia, Facilidade e Liquidez. Trata-se de uma aplicação muito mais segura do que o mercado variável, uma vez que, como investidor, você mesmo poderá decidir como deseja usar a carta de crédito contemplada.',
    description2: 'Através do investimento com cartas de consórcio de imóveis e/ou veículos, você possui um investimento altamente flexível, seja para aumentar seu patrimônio ou gerar uma renda extra.',
    description3: 'Entre em contato e vamos te auxiliar em investimentos com a HS Consórcios!',
    buttonBg: '#E2A029',
    imgStart: true,
    img: "/images/investimento.jpg",
    alt: 'Investimento',
    imageBackgroundColor: '#F2D9AC',
    imageBgBorderRadius: '40px 40px 40px 40px',
    valorDasParcelas: 'R$ 197,20',
    fraseDasParcelas: 'Comece a investir no seu futuro com as parcelas flexíveis do consórcio!',
};