import React from 'react'
import Base from './base';
import VeiculosPage from '../components/pages/veiculos';

const Veiculos = () => {

    return (
        <Base>
            <VeiculosPage />            
        </Base>
    )
}

export default Veiculos
