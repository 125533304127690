import React, {useState} from 'react'

// components
import { Button } from "@material-ui/core";

// styling
import { makeStyles } from '@material-ui/core/styles';

// custom components
import { 
    Column1, 
    Column2, 
    Heading, 
    Img, 
    ImgWrap, 
    InfoContainer, 
    InfoRow, 
    InfoWrapper, 
    Subtitle, 
    TextWrapper, 
    ImageBackgroundShape,
} from './InfoElements'
import FormSlider from '../../../base/Common/ContactForm/FormSlider';

const useStyles = makeStyles({
    myButton: props => ({
      background: props.backgroundColor,
      height: '60px',
      minWidth: '200px',
      "&:hover": {
          backgroundColor: props.backgroundColor
      },
      textTransform: 'none',
      marginTop: '32px',
      fontSize: '16px',
      fontWeight: 'bold',
      color: props.textColor,
      "@media screen and (max-width: 479px)": {
          backgroundColor: props.buttonMobileBg,
          borderRadius: '8px',
          height: '48px',
          fontWeight: 'normal',
          minWidth: '180px',
          marginTop: '24px'
      }
    }),
  });

const Buttons = ({
    bgColor,
    btnTextColor,
    buttonMobileBg,
    onClickAction
}) => {
    const props ={ backgroundColor: bgColor, textColor: btnTextColor, buttonMobileBg: buttonMobileBg };
    const classes = useStyles(props);

    return (
        <Button
            className={classes.myButton}
            variant="contained"
            color="secondary"
            type="submit"
            onClick={() => onClickAction()}
        >
            Começar agora
        </Button>
    )
}

const InfoSection = ({
    bgColor,
    id, 
    imgStart,
    headline,
    description,
    buttonBg,
    img,
    alt,
    imageBackgroundColor,
    imageBgBorderRadius,
    btnTextColor,
    mobileBgColor,
    mobileTextColor,
    buttonMobileBg,
}) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <InfoContainer bgColor={bgColor} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1 imgStart={imgStart} mobileBgColor={mobileBgColor}>
                            <TextWrapper>
                                <Heading mobileTextColor={mobileTextColor} >{headline}</Heading>
                                <Subtitle mobileTextColor={mobileTextColor} >{description}</Subtitle>
                                <Buttons bgColor={buttonBg} btnTextColor={btnTextColor} buttonMobileBg={buttonMobileBg} onClickAction={() => setOpen(true)} />
                            </TextWrapper>
                        </Column1>
                        <Column2 imgStart={imgStart}>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                            <ImageBackgroundShape bgColor={imageBackgroundColor} borderRadius={imageBgBorderRadius} imgStart={imgStart} />
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
            <FormSlider open={open} handleClose={() => setOpen(false)} initialStep={1} initialType='Investimento' />
        </>
    )
}

export default InfoSection
