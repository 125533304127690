import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyBvIXj_ThDBzy4Ik9FI8hyutIF6XieTxes",
    authDomain: "dahmer-consorcios.firebaseapp.com",
    projectId: "dahmer-consorcios",
    storageBucket: "dahmer-consorcios.appspot.com",
    messagingSenderId: "150502923991",
    appId: "1:150502923991:web:62edb3716e4d23ba739a92",
    measurementId: "G-DM4RLRRHSQ"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const analytics = firebase.analytics();
