const Img1 = '/images/veiculos-info-1.jpg'
const Img2 = '/images/veiculos-info-2.jpg'

export const Section1 = {
    bgColor: '#F6F6F6', 
    img: Img1,
    alt: "Veículos",
    imgStart: true,
    imageBackgroundColor: "#00ff00" ,
    argNumberStart: 1,
    cards: [
        {
            title: "Veículos novos ou usados",
            description: "Na HS Consórcios você é quem decide como utilizar o seu dinheiro em relação ao seu próximo veículo. Novo ou usado, estamos aqui para te ajudar."
        },
        {
            title: "Metade da parcela até a contemplação",
            description: "A HS Consórcios, pensando em seus clientes, disponibiliza a possibilidade de pagamento de metade do valor das parcelas do Consórcio até a contemplação."
        },
        {
            title: "Seu usado como lance",
            description: "Nós temos a possibilidade de você usar o seu próprio veículo usado como lance. Entre em contato conosco que vamos te explicar o passo-a-passo dessa opção!"
        }
    ]
};

export const Section2 = {
    bgColor: '#F6F6F6',
    img: Img2,
    alt: "Veículos",
    imgStart: false,
    imageBackgroundColor: "#00ff00",
    argNumberStart: 4,
    cards: [
        {
            title: "Até 30% do crédito para lance",
            description: "Caso você desejar participar da contemplação por lance, você vai ter a flexibilidade de usar até 30% do valor da sua carta de consórcio como parte do lance."
        },
        {
            title: "Formas de contemplação",
            description: " São mais de 850 contemplações mensais através de sorteio pela loteria federal e opções lances fixos e livre. Venha ser o próximo contemplado!"
        },
        {
            title: "Prazos Flexíveis",
            description: "No Consórcio de veículos você pode selecionar o prazo que melhor funciona para você. Possuímos opções de 36 a até 140 meses."
        }
    ]
};
