import React from 'react';
import { HeroBg, HeroWrap, HeroContainer, ImageBg, LeftPanel, FormPanel, FormWrap, LogoImg, HeroTitle, TitleLine, TitleLineDiv, HeroSubtitle, LeftPanelContent, SubtitleHighlight } from "./HeroElements";
import HeroForm from './Form/HeroForm';

const HeroSection = ({initialStep, initialType, contemplados, cards, dialogClose}) => {
    return <HeroContainer id="home">
        <HeroBg>
            <ImageBg src={"/images/hero-bg.jpg"} alt={"landingBg"}></ImageBg>            
        </HeroBg>        
        <FormWrap>            
            <FormPanel>
                <LeftPanel>
                    <LeftPanelContent>
                        <LogoImg src="/images/logo.png" />
                        <HeroTitle>Dahmer Consórcios</HeroTitle>
                        <TitleLineDiv>
                            <TitleLine />
                        </TitleLineDiv>
                        <HeroSubtitle>Pronto para transformar seu <SubtitleHighlight>sonho</SubtitleHighlight> em realidade?</HeroSubtitle>
                    </LeftPanelContent>
                </LeftPanel>
                <HeroWrap>
                    <HeroForm initialStep={initialStep} initialType={initialType} contemplados={contemplados} cards={cards} dialogClose={dialogClose} />
                </HeroWrap>
            </FormPanel>
        </FormWrap>
        
    </HeroContainer>
}
export default HeroSection