const Img1 = '/images/imoveis-info-1.jpg'
const Img2 = '/images/imoveis-info-2.jpg'

export const Section1 = {
    bgColor: '#F6F6F6', 
    img: Img1,
    alt: "Imóveis",
    imgStart: true,
    imageBackgroundColor: "#00ff00" ,
    argNumberStart: 1,
    cards: [
        {
            title: "Compra - Construção - Reforma",
            description: "Com o Consórcio você decide como utilizar o seu dinheiro. Seja para comprar, construir ou reformar o seu imóvel, nós vamos te auxiliar."
        },
        {
            title: "Metade da parcela até a contemplação",
            description: "A HS Consórcios, pensando em seus clientes, disponibiliza a possibilidade de pagamento de metade do valor das parcelas do Consórcio até a contemplação."
        },
        {
            title: "Poder de compra à vista",
            description: "Após a contemplação, você decide como, onde e quando utilizar a carta de crédito, podendo fazer ótimos negócios pagando à vista."
        }

    ]
};

export const Section2 = {
    bgColor: '#F6F6F6',
    img: Img2,
    alt: "Imóveis",
    imgStart: false,
    imageBackgroundColor: "#00ff00",
    argNumberStart: 4,
    cards: [
        {
            title: "Possibilidade de uso do seu FGTS",
            description: "Pode utilizar o FGTS para oferta e pagamento de Lance, complemento da Carta de Crédito e amortização do saldo devedor, conforme normas do FGTS."
        },
        {
            title: "Até 30% do crédito para lance",
            description: "Caso você desejar participar da contemplação por lance, você vai ter a flexibilidade de usar até 30% do valor da sua carta de consórcio como parte do lance."
        },
        {
            title: "Prazo de até 200 meses",
            description: "O Consórcio possui diversas flexibilidades e benefícios para você, entre eles, está o prazo de pagamento das parcelas de até 200 meses."
        }
    ]
};
