import styled from 'styled-components';

export const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
`;

export const LabelValueContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
`;

export const LabelText = styled.label`
    color: #2f2f2f;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
`;

export const ValueText = styled.label`
    color: #2f2f2f;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: left;

    margin-left: 12px;
`;

export const Separator = styled.div`
    margin-top: 48px;
`;