const { useEffect } = require("react");

const useEnterKeyListener = ({ querySelectorToExecuteClick }) => {
    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                handlePressEnter();
                // console.log("Selecting from query: " + querySelectorToExecuteClick);
            }
        };

        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, []);

    const handlePressEnter = () => {
        const mouseClickEvents = ["mousedown", "click", "mouseup"];
        function simulateMouseClick(element) {
            if (element) {
                mouseClickEvents.forEach((mouseEventType) =>
                    element.dispatchEvent(
                        new MouseEvent(mouseEventType, {
                            view: window,
                            bubbles: true,
                            cancelable: true,
                            buttons: 1,
                        })
                    )
                );
            } else {
                // console.log("Enter called but no element attached.");
            }
        }

        var element = document.querySelector(querySelectorToExecuteClick);
        simulateMouseClick(element);
    };
};

export default useEnterKeyListener;