import styled from 'styled-components';

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({bgColor}) => (bgColor)};
    clip-path: ${({polygon}) => (polygon === 'polygon2' ? 'polygon(0 40%, 100% 0%, 100% 100%, 0 100%)' : 'unset')};
    
    @media screen and (max-width: 768) {
        padding: 100px 0;
    }

    @media screen and (max-width: 479px) {
        clip-path: none;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;

    @media screen and (min-width: 480px) {
        height: ${({height}) => (height ? height : '700px')};
    }
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col2 col1';

    @media screen and (max-width: 479px) {
        grid-template-areas: 'col1 col1' 'col2 col2';
    }
`;

export const Column1 = styled.div`
    grid-area: col1;
    margin: auto;

    @media screen and (max-width: 479px) {
        margin: unset;
    }
`;

export const Column2 = styled.div`
    grid-area: col2;
    position: relative;
    margin: auto;
`;

export const TextWrapper = styled.div`
    max-width: 600px;
    padding-top: 0;
    margin-top: ${({marginTop}) => (marginTop)};
    /* padding-bottom: 60px; */

    @media screen and (max-width: 479px) {
        margin: 32px 32px 0 32px;
        margin-bottom: ${({marginBottom}) => (marginBottom)};
        margin-top: ${({marginTopMobile}) => (marginTopMobile)};
    }
`;

export const LargeTextContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const LargeText = styled.h1`
    font-size: 140px;
    font-weight: normal;
    color: ${({textColor}) => (textColor ? textColor : '#2f2f2f')};
    text-align: left;

    @media screen and (max-width: 479px) {
        font-size: 48px;
        font-weight: 600;
    }
`;

export const LargeSubText = styled.h2`
    font-size: 80px;
    font-weight: normal;
    color: #2F2F2F;
    text-align: left;

    @media screen and (max-width: 479px) {
        font-size: 32px;
        font-weight: 600;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '600')};
    color: ${({textColor}) => (textColor ? textColor : '#2f2f2f')};
    text-align: left;
    
    @media screen and (max-width: 479px) {
        font-size: 26px;
        font-weight: 600;
        margin-top: 12px;
        line-height: 36px;
        margin-bottom: 12px;
    }
`;

export const Subtitle = styled.p`
    font-size: 18px;
    line-height: 36px;
    text-align: left;
    color: ${({textColor}) => (textColor ? textColor : '#000')};
    line-height: ${({lineHeight}) => (lineHeight)};
    
    @media screen and (max-width: 479px) {
        font-size: 16px;
        font-weight: normal;
        line-height: 28px;
    }
`;

export const ImgWrap = styled.div`
    max-width: 725px;
    height: 100%;
    position: relative;
    z-index: 10;

    @media screen and (max-width: 479px) {
        margin-top: 32px;
    }
`;

export const Img = styled.img`
    width: 100%;
    padding-right: 0;
    opacity: 1;
    z-index: 10;

    @media screen and (max-width: 479px) {
        vertical-align: -webkit-baseline-middle;
    }
`;
