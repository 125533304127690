import React from 'react'
import { withRouter } from 'react-router-dom'
import {animateScroll as scroll} from 'react-scroll';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        scroll.scrollToTop();
      }
    }
  
    render() {
      return this.props.children
    }
  }
  
export default withRouter(ScrollToTop)
  