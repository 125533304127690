import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";

export const HeroContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: flex-start;
    height: 960px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 479px) {
        height: 667px;
    }
`;

export const HeroBg = styled.div`
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 2;
    height: 100%;
    overflow: hidden;
    z-index: 3;
    animation: fade 3s infinite;
`;

export const ScrimBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background-image: linear-gradient(hsla(0, 0%, 0%, 0.5), 70%, hsla(0, 0%, 100%, 0));
`

export const ImageBg = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity: ${({op}) => op};
`;

export const HeroWrap = styled.div`
    position: absolute;
    width: 100%;
    padding-top: 200px;
    height: 1080px;
    z-index: 4;

    @media screen and (max-width: 600px) {
        padding-top: 150px;
    }

    @media screen and (max-width: 479px) {
        padding: 150px 32px 0 32px;
    }
`;

export const HeroH1 = styled.h1`
    color: #fff;
    font: normal normal medium 86px/100px Franklin Gothic;
    font-size: 67px;
    font-weight: 100px;
    text-align: center;
    text-shadow: 0.5px 0.5px #010101;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px 30px 30px;

    @media screen and (max-width: 1200px) {
        font-size: 48px;
    }

    @media screen and (max-width: 479px) {
        font-size: 28px;
        text-align: start;
        padding: 0 0 16px 0;
    }
`;

export function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map(str => <p>{str}</p>);
}


export const HeroH2 = styled.h2`
    color: #fff;
    font: normal normal normal 24px/40px Segoe UI;
    font-size: 25px;
    font-weight: 40px;
    text-align: center;
    text-shadow: 0.5px 0.5px #010101;
    max-width: 900px;
    margin: 0 auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0 30px 0px 30px;

    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }

    @media screen and (max-width: 479px) {
        font-size: 16px;
        line-height: 24px;
        text-align: start;
        padding: 0 0 16px 0;
    }
`;

export const HeroSpan = styled.span`
    color: #DF6E3A;
`

export const FormWrap = styled.div`
    position: absolute;
    min-width: 250px;
    right: 0px;
    bottom: 0px;
    padding-right: 120px;
    padding-bottom: 270px;
    height: auto;
    z-index: 4;

    @media screen and (max-width: 1200px) {
        position: relative;
        padding-top: 80px;
        padding-right: 0px;
    }
    
    @media screen and (max-width: 700px) {
        min-width: 100px;
    }
    
    @media screen and (max-width: 400px) {
        padding-top: 20px;
    }
`;

export const FormContent = styled.div`
    height: 200px;;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
`;

const useStyles = makeStyles({
    button: props => ({
        background: props.backgroundColor,
        width: '200px',
        margin: '0 auto',
        marginRight: '12px',
        height: '60px',
        opacity: 0.9,
        "&:hover": {
            opacity: 1,
            backgroundColor: props.backgroundColor
        },
        "@media screen and (max-width: 479px)": {
            width: '60%',
            height: '40px'
        }
    })
});

export const HeroButton = ({children, icon, bgColor, link, onClickAction}) => {
    return <Button
            className={useStyles({backgroundColor: bgColor}).button}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={icon}
            style={{
                margin: '0 auto',
                marginBottom: '12px'
            }}
            onClick={onClickAction}
            target="_blank"
            href={link}>
                {children}
        </Button>
};