import React from 'react'
import HomePage from '../components/pages/home';
import Base from './base';

const Home = () => {

    return (
        <Base isHome>
            <HomePage />            
        </Base>
    )
}

export default Home
