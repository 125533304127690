export const ContempladosMasterData = [
    {
        id: 1,
        tipo: 'veiculo',
        credito: '25.100,00',
        entrada: '10.300,00',
        parcela: '331,00',
        prazo: '81 meses',
        checked: false
    },
    {
        id: 2,
        tipo: 'veiculo',
        credito: '25.800,00',
        entrada: '10.900,00',
        parcela: '507,00',
        prazo: '44 meses',
        checked: false
    },
    {
        id: 3,
        tipo: 'veiculo',
        credito: '28.200,00',
        entrada: '11.900,00',
        parcela: '361,00',
        prazo: '92 meses',
        checked: false
    },
    {
        id: 4,
        tipo: 'veiculo',
        credito: '31.500,00',
        entrada: '13.200,00',
        parcela: '555,00',
        prazo: '53 meses',
        checked: false
    },
    {
        id: 5,
        tipo: 'veiculo',
        credito: '33.500,00',
        entrada: '13.700,00',
        parcela: '549,00',
        prazo: '61 meses',
        checked: false
    },
    {
        id: 6,
        tipo: 'veiculo',
        credito: '35.400,00',
        entrada: '14.900,00',
        parcela: '720,00',
        prazo: '44 meses',
        checked: false
    },
    {
        id: 7,
        tipo: 'veiculo',
        credito: '38.372,00',
        entrada: '15.700,00',
        parcela: '521,00',
        prazo: '68 meses',
        checked: false
    },
    {
        id: 8,
        tipo: 'veiculo',
        credito: '39.700,00',
        entrada: '15.900,00',
        parcela: '621,00',
        prazo: '47 meses',
        checked: false
    },
    {
        id: 9,
        tipo: 'veiculo',
        credito: '41.800,00',
        entrada: '16.900,00',
        parcela: '709,00',
        prazo: '57 meses',
        checked: false
    },
    {
        id: 10,
        tipo: 'veiculo',
        credito: '44.920,00',
        entrada: '18.900,00',
        parcela: '649,00',
        prazo: '67 meses',
        checked: false
    },
    {
        id: 11,
        tipo: 'veiculo',
        credito: '47.800,00',
        entrada: '19.950,00',
        parcela: '899,00',
        prazo: '55 meses',
        checked: false
    },
    {
        id: 12,
        tipo: 'veiculo',
        credito: '58.900,00',
        entrada: '24.900,00',
        parcela: '752,00',
        prazo: '86 meses',
        checked: false
    },
    {
        id: 13,
        tipo: 'veiculo',
        credito: '69.000,00',
        entrada: '29.000,00',
        parcela: '933,00',
        prazo: '84 meses',
        checked: false
    },
    {
        id: 14,
        tipo: 'veiculo',
        credito: '73.172,00',
        entrada: '30.900,00',
        parcela: '948,00',
        prazo: '82 meses',
        checked: false
    },
    {
        id: 15,
        tipo: 'veiculo',
        credito: '79.249,00',
        entrada: '33.500,00',
        parcela: '1.146,00',
        prazo: '64 meses',
        checked: false
    },
    {
        id: 16,
        tipo: 'veiculo',
        credito: '98.450,00',
        entrada: '42.500,00',
        parcela: '981,00',
        prazo: '91 meses',
        checked: false
    },
    {
        id: 17,
        tipo: 'imovel',
        credito: '56.300,00',
        entrada: '18.900,00',
        parcela: '448,00',
        prazo: '164 meses',
        checked: false
    },
    {
        id: 18,
        tipo: 'imovel',
        credito: '71.000,00',
        entrada: '19.900,00',
        parcela: '599,00',
        prazo: '130 meses',
        checked: false
    },
    {
        id: 19,
        tipo: 'imovel',
        credito: '86.700,00',
        entrada: '29.900,00',
        parcela: '791,00',
        prazo: '127 meses',
        checked: false
    },
    {
        id: 20,
        tipo: 'imovel',
        credito: '93.400,00',
        entrada: '30.000,00',
        parcela: '1.058,00',
        prazo: '114 meses',
        checked: false
    },
    {
        id: 21,
        tipo: 'imovel',
        credito: '97.000,00',
        entrada: '35.000,00',
        parcela: '873,00',
        prazo: '120 meses',
        checked: false
    },
    {
        id: 22,
        tipo: 'imovel',
        credito: '117.018,00',
        entrada: '35.000,00',
        parcela: '935,00',
        prazo: '140 meses',
        checked: false
    },
    {
        id: 23,
        tipo: 'imovel',
        credito: '128.400,00',
        entrada: '40.000,00',
        parcela: '1.245,00',
        prazo: '125 meses',
        checked: false
    },
    {
        id: 24,
        tipo: 'imovel',
        credito: '141.230,00',
        entrada: '43.000,00',
        parcela: '1.097,00',
        prazo: '167 meses',
        checked: false
    },
    {
        id: 25,
        tipo: 'imovel',
        credito: '164.067,00',
        entrada: '54.000,00',
        parcela: '1.295,00',
        prazo: '128 meses',
        checked: false
    },
    {
        id: 26,
        tipo: 'imovel',
        credito: '167.000,00',
        entrada: '49.900,00',
        parcela: '1.533,00',
        prazo: '120 meses',
        checked: false
    },
    {
        id: 27,
        tipo: 'imovel',
        credito: '172.392,00',
        entrada: '55.000,00',
        parcela: '1.263,00',
        prazo: '171 meses',
        checked: false
    },
    {
        id: 28,
        tipo: 'imovel',
        credito: '183.800,00',
        entrada: '65.000,00',
        parcela: '1.740,00',
        prazo: '116 meses',
        checked: false
    },
    {
        id: 29,
        tipo: 'imovel',
        credito: '189.112,00',
        entrada: '59.900,00',
        parcela: '1.716,00',
        prazo: '109 meses',
        checked: false
    },
    {
        id: 30,
        tipo: 'imovel',
        credito: '195.871,00',
        entrada: '62.000,00',
        parcela: '1.432,00',
        prazo: '159 meses',
        checked: false
    },
    {
        id: 31,
        tipo: 'imovel',
        credito: '198.500,00',
        entrada: '76.000,00',
        parcela: '1.512,00',
        prazo: '138 meses',
        checked: false
    },
    {
        id: 32,
        tipo: 'imovel',
        credito: '201.500,00',
        entrada: '68.000,00',
        parcela: '1.939,00',
        prazo: '121 meses',
        checked: false
    },
    {
        id: 33,
        tipo: 'imovel',
        credito: '201.649,00',
        entrada: '69.900,00',
        parcela: '1.479,00',
        prazo: '171 meses',
        checked: false
    },
    {
        id: 34,
        tipo: 'imovel',
        credito: '252.392,00',
        entrada: '81.000,00',
        parcela: '1.926,00',
        prazo: '170 meses',
        checked: false
    },
    {
        id: 35,
        tipo: 'imovel',
        credito: '271.000,00',
        entrada: '69.500,00',
        parcela: '2.615,00',
        prazo: '130 meses',
        checked: false
    },
    {
        id: 36,
        tipo: 'imovel',
        credito: '283.000,00',
        entrada: '75.000,00',
        parcela: '2.233,00',
        prazo: '186 meses',
        checked: false
    },
    {
        id: 37,
        tipo: 'imovel',
        credito: '322.000,00',
        entrada: '110.000,00',
        parcela: '2.154,00',
        prazo: '185 meses',
        checked: false
    },
    {
        id: 38,
        tipo: 'imovel',
        credito: '368.200,00',
        entrada: '119.900,00',
        parcela: '3.471,00',
        prazo: '122 meses',
        checked: false
    },
    {
        id: 39,
        tipo: 'imovel',
        credito: '403.524,00',
        entrada: '154.000,00',
        parcela: '3.047,00',
        prazo: '152 meses',
        checked: false
    },
    {
        id: 40,
        tipo: 'imovel',
        credito: '470.800,00',
        entrada: '156.000,00',
        parcela: '3.435,00',
        prazo: '152 meses',
        checked: false
    },
]