import React from 'react'
import InfoSection from './InfoSection'
import { homeSectionImoveis, homeSectionVeiculos, homeSectionInvestimentos } from '../home/InfoSection/Data';
import ComparisonSection from './ComparisonSection';
import HeroSection from '../../base/Common/ContactForm/HeroSection';

const HomePage = () => {
    return (
        <>
            <HeroSection />
            <InfoSection {...homeSectionImoveis} />
            <InfoSection {...homeSectionVeiculos} />
            <InfoSection {...homeSectionInvestimentos} />
            <ComparisonSection />
        </>
    )
}

export default HomePage
