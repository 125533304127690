import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Img = styled.img`
    width: 194px;

    @media screen and (max-width: 700px) {
        width: 130px;
    }
`

export const Nav = styled.nav`
    height: 120px;
    margin-top: -120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 99;
    
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(30px);

    /* Workaround for Mozilla Firefox off-by-default backdrop-filter */
    @-moz-document url-prefix() {
        background-color: rgba(255, 255, 255, 0.9);
    }
    
    @media screen and (max-width: 1330px) {
        transition: 0.8s all ease;
        backdrop-filter: unset;
        background-color: #32333A;
    }
    
    @media screen and (max-width: 700px) {
        height: 86px;
        margin-top: -86px;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    height: 120px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;

    @media screen and (max-width: 700px) {
        height: 86px;
    }
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;

    @media screen and (max-width: 700px) {
        margin-left: 0px;
    }
`;

export const NavLogoHS = styled.div`
    color: #fff;
    justify-self: flex-start;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-right: 24px;
    font-weight: bold;
    text-decoration: none;

    @media screen and (max-width: 1500px) {
        margin-left: 32px;
    }
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 1330px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 150%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }

    @media screen and (max-width: 700px) {
        font-size: 1.1rem;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    /* margin-left: -160px; */
    /* margin-right: -22px; */

    @media screen and (max-width: 1330px) {
        display: none
    }
`;

export const NavItem = styled.li`
    height: 120px;

    @media screen and (max-width: 700px) {
        height: 50px;
    }
`;

export const NavSeparator = styled.li`
    height: 71px;
    border-left: 1px solid #2F2F2F;
    opacity: 1;
`

export const NavIntLinks = styled(LinkS)`
    color: #2f2f2f;
    font: normal normal 600 18px/14px Segoe UI;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 50%;
    transform: translate(0, 50%);
    cursor: pointer;
    font-family: 'Encode Sans Expanded', sans-serif;

    &.active {
        border-bottom: 2px solid #B31923;
    }

    &:hover {
        color: #E74645;
    }
`;

export const NavExtLinks = styled(LinkR)`
    color: #2f2f2f;
    font: normal normal 600 18px/14px Segoe UI;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 50%;
    transform: translate(0, 50%);
    cursor: pointer;
    font-family: 'Encode Sans Expanded', sans-serif;

    &.active {
        border-bottom: 2px solid #B31923;
    }

    &:hover {
        color: #E74645;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;

    @media screen and (max-width: 1330px) {
        display: none;
    }
`;

export const NavBtnLink = styled(LinkR)`
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 10px 22px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transform: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`;