import React from 'react'

// components
import { Button } from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';

// integration
import { openWhatsApp } from '../../../integration/whatsapp'

// styling
import { makeStyles } from '@material-ui/core/styles';
import {animateScroll as scroll} from 'react-scroll';

// custom components
import { 
    Column1, 
    Column2, 
    Heading, 
    Img, 
    ImgWrap, 
    InfoContainer, 
    InfoRow, 
    InfoWrapper, 
    Subtitle, 
    TextWrapper, 
    AllButtonsWrapper, 
    SendWppEmailWrapper, 
    ImageBackgroundShape, 
    ParcelasWrap, 
    ParcelasContainer, 
    ParcelasTitleWrap, 
    ParcelasTitleText, 
    ParcelasBodyLargerText,
    ParcelasBodySmallerText,
    ParcelasDivider
} from './InfoElements'

const useStyles = makeStyles({
    sendWpp: props => ({
      background: props.backgroundColor,
      width: '100%',
      margin: '0 auto',
      marginRight: '12px',
      height: '60px',
      "&:hover": {
          backgroundColor: props.backgroundColor
      },
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      "@media screen and (max-width: 479px)": {
          marginRight: 'unset',
          marginBottom: '12px',
      }
    }),

    sendEmail: props => ({
      background: props.backgroundColor,
      width: '100%',
      margin: '0 auto',
      height: '60px',
      "&:hover": {
        backgroundColor: props.backgroundColor
      },
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    }),
  });

const Buttons = ({
    bgColor,
    tipo
}) => {
    const props ={ backgroundColor: bgColor };
    const classes = useStyles(props);

    return (
        <AllButtonsWrapper>
            <SendWppEmailWrapper>
                <Button
                    className={classes.sendWpp}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<WhatsAppIcon />}
                    onClick={() => openWhatsApp({tipo: tipo})}
                >
                    Enviar Whats
                </Button>
                <Button 
                    className={classes.sendEmail}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<EmailIcon />}
                    onClick={() => scroll.scrollToTop()}
                >
                    Enviar E-mail
                </Button>
            </SendWppEmailWrapper>
        </AllButtonsWrapper>
    )
}

const InfoSection = ({
    bgColor,
    id,
    id_alt,
    imgStart,
    topLine,
    headline,
    description,
    description2,
    description3,
    buttonBg,
    img,
    alt,
    imageBackgroundColor,
    imageBgBorderRadius,
    valorDasParcelas,
    fraseDasParcelas,
}) => {
    return (
        <>
            <InfoContainer bgColor={bgColor} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1 imgStart={imgStart}>
                            <TextWrapper>
                                <Heading >{headline}</Heading>
                                <Subtitle>
                                    {description}
                                    <br></br>
                                    <br></br>
                                    {description2}
                                    {description3 &&
                                        <>
                                            <br></br>
                                            <br></br>
                                            {description3}
                                        </>
                                    }
                                </Subtitle>
                                <Buttons bgColor={buttonBg} tipo={id_alt} />
                            </TextWrapper>
                        </Column1>
                        <Column2 imgStart={imgStart}>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                                <ParcelasWrap imgStart={imgStart}>
                                    <ParcelasTitleWrap bgColor={buttonBg}>
                                        <ParcelasTitleText>Parcelas de</ParcelasTitleText>
                                    </ParcelasTitleWrap>
                                    <ParcelasContainer>
                                        <ParcelasBodyLargerText>{valorDasParcelas}</ParcelasBodyLargerText>
                                        <ParcelasDivider color={buttonBg} />
                                        <ParcelasBodySmallerText>{fraseDasParcelas}</ParcelasBodySmallerText>
                                    </ParcelasContainer>
                                </ParcelasWrap>
                            </ImgWrap>
                            <ImageBackgroundShape bgColor={imageBackgroundColor} borderRadius={imageBgBorderRadius} imgStart={imgStart} />
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>   
        </>
    )
}

export default InfoSection
