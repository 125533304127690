import React, { useEffect, useState } from 'react'
import FormSlider from '../../base/Common/ContactForm/FormSlider';
import HeroSection from './HeroSection';
import { ContempladosMasterData } from './MasterData';
import MobileVersion from './MobileVersion';

const ContempladosPage = () => {
    const [width, setWidth] = useState(window.innerWidth);    
    const breakpoint = 480;

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);

        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);


    const [contempladosState, setContempladosState] = useState({ contemplados: ContempladosMasterData });

    const [tabSelected, setTabSelected] = useState('all');
    const onTabClick = (tab) => {
        setTabSelected(tab);
    }

    const filteredContemplados = tabSelected !== 'all' ? 
        contempladosState.contemplados.filter(c => c.tipo === tabSelected)
        :
        contempladosState.contemplados
    ;


    const handleIndividualCheckChange = (id) => {
        
        // Desmarca o allChecked checkbox
        // pode ser otimizado verificando se a checkbox que está sendo alterada realmente está marcada
        if (allChecked) {
            setAllChecked(false);
        }

        const newState = {...contempladosState, contemplados: [...contempladosState.contemplados]};

        const matchingIndex = newState.contemplados.findIndex((item) => item.id === id);
        if (matchingIndex !== -1) {
            newState.contemplados[matchingIndex] = {
                ...newState.contemplados[matchingIndex],
                checked: !newState.contemplados[matchingIndex].checked
            }
        }

        setContempladosState(newState);
    }

    const [allChecked, setAllChecked] = React.useState(false);

    const handleAllChecked = (event) => {
        setAllChecked(event.target.checked);

        const newState = {...contempladosState, contemplados: [...contempladosState.contemplados]};
        newState.contemplados.map((c, index) => {
            newState.contemplados[index] = {
                ...newState.contemplados[index],
                checked: !allChecked
            }
        })
        setContempladosState(newState);
    };
    
    const [notificationOpen, setNotificationOpen] = React.useState(false);

    const [notificationMessage, setNotificationMessage] = React.useState();

    const openNotification = () => {
        setNotificationMessage("Nenhuma carta selecionada!");
        setNotificationOpen(true);
    }

    const onClickSomarCartas = () => {
        contempladosState.contemplados.filter(c => c.checked === true).length > 0 ? 
            handleDialogClickOpen()
        :
            openNotification()
    }

    // dialog
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    }

    const handleDialogClickClose = () => {
        setDialogOpen(false);
    }

    // full screen dialog (form)
    const [formOpen, setFormOpen] = useState(false);
    const [formCards, setFormCards] = useState([]);
    const handleOpenContactForm = (cartas) => {
        setFormCards(cartas);
        setFormOpen(true);
    }

    return (
        <div id="contemplados">
            {width < breakpoint ? 
                <MobileVersion 
                    contempladosState={contempladosState}
                    setContempladosState={setContempladosState}
                    handleIndividualCheckChange={handleIndividualCheckChange}
                    allChecked={allChecked}
                    handleAllChecked={handleAllChecked}
                    onTabClick={onTabClick}
                    tabSelected={tabSelected}
                    onClickSomarCartas={onClickSomarCartas}
                    filteredContemplados={filteredContemplados}
                    notificationMessage={notificationMessage}
                    notificationOpen={notificationOpen}
                    setNotificationOpen={setNotificationOpen}
                    dialogOpen={dialogOpen}
                    handleDialogClickClose={handleDialogClickClose}
                    openContactForm={handleOpenContactForm}
                /> : 
                <HeroSection 
                    contempladosState={contempladosState}
                    setContempladosState={setContempladosState}
                    handleIndividualCheckChange={handleIndividualCheckChange}
                    allChecked={allChecked}
                    handleAllChecked={handleAllChecked}
                    onTabClick={onTabClick}
                    tabSelected={tabSelected}
                    onClickSomarCartas={onClickSomarCartas}
                    filteredContemplados={filteredContemplados}
                    notificationMessage={notificationMessage}
                    notificationOpen={notificationOpen}
                    setNotificationOpen={setNotificationOpen}
                    dialogOpen={dialogOpen}
                    handleDialogClickClose={handleDialogClickClose}
                    openContactForm={handleOpenContactForm}
                />
            }
            <FormSlider open={formOpen} handleClose={() => setFormOpen(false)} initialStep={2} contemplados={true} cards={formCards}  />
        </div>
    )
}

export default ContempladosPage
